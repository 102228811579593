.PayUPayment {
    margin-top: 1rem;

    .PayUPaymentLinks {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;

        .PayUPaymentLinks-SingleLink {
            padding: .5rem;
            border: 1px solid var(--checkout-payment-border-color);
            border-radius: 0;

            &_active {
                border-color: var(--button-border);
            }

            &:hover {
                border-color: var(--button-border);
                transition: border-color ease-in .5s;
            }
        }
    }
}
