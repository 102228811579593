body {
    &.viallidesign {
        --option-background-color: $vialli-lightgray1;

        .CategoryFilterOverlay {
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 2;
            grid-row-end: 2;


            background: $vialli-lightgray1;

            > * {
                background: $vialli-lightgray1;
            }


            @include mobile {
                top: 100px;
                height: calc(100% - 50px);
            }


            @include desktop {
                z-index: auto;
                padding: 32px 24px 0 90px;
            }

            &-Heading {
                font-family: $vialli-secondary-font;
                font-weight: normal;
                font-size: 25px;
                line-height: 33px;
                letter-spacing: 0;
                color: $vialli-darkgray2;
                opacity: 1;
            }

            .Field {
                margin-bottom: 5px;

                label {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
}
