:root {
    --mepal-primary-color: #75a3a2;
    --mepal-secondary-color: #f5a061;
    --mepal-third-color: #62636F;
}

:root.mepal {
    --primary-base-color: var(--mepal-secondary-color);
}

@media (max-width: 1500px) and (min-width: 1160px) {
    body.mepal {
        --content-wrapper-width: calc(100vw - 200px);
    }
}

@import 'button';
@import 'homepage-slider';
@import 'slider';
@import 'homepage-category-preview';
@import 'homepage-product-series';
@import 'homepage-news';
@import 'product-list-page';
@import 'promotion';
@import 'widget';
@import 'read-more';
@import 'input';
@import 'downloads';
@import 'contact-page';
@import 'components/components';
@import 'route/route';

body {
    &.mepal {
        font-family: $font-dosis;
    }
}
