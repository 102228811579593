body {
    &.mepalmio {

        .HomePage {
            .HomepageCategories {
                @include desktop {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}
