body {
    &.viallidesign {
        .CartPage-TableHead {
            span:nth-child(2) {

                @media (max-width: 1199px) {
                    display: none;
                }
            }
        }
    }
}

