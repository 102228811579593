body {
    &.mepal {
        .Footer {
            --footer-background-color: var(--mepal-primary-color);

            &-ColumnContent {
                width: 100%;
            }

            &-Content {

                .Footer-Column_isNewsletter {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0 16px;
                    background-color: var(--mepal-secondary-color);
                    color: #fff;

                    h3,
                    p {
                        line-height: 32px;
                    }

                    .Input-Wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    h3 {
                        font-weight: 700;
                        font-size: 28px;
                        margin-bottom: 0;
                    }

                    p {
                        margin-top: 0;
                        margin-bottom: 8px;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    input,
                    button {
                        height: 40px;
                    }

                    input {
                        --placeholder-color: var(--mepal-third-color);
                        border: none;
                        font-size: 16px;
                        border-radius: 0 0 0 12px;
                        width: 100%;

                        &::placeholder {
                            font-size: 16px;
                            color: var(--placeholder-color);
                        }

                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            font-size: 16px;
                            color: var(--placeholder-color);
                        }

                        &::-ms-input-placeholder { /* Microsoft Edge */
                            font-size: 16px;
                            color: var(--placeholder-color);
                        }
                    }

                    button {
                        font-size: 16px;
                        border-radius: 0 0 8px 0;
                        padding: 0;
                        @extend .Button.Third;

                        @media (max-width: 450px) {
                            max-width: 100px;
                        }
                    }
                }

                .CmsBlock-Wrapper {


                    .ContentWrapper {
                        grid-gap: 3rem;
                        grid-template-columns:auto;

                        @include desktop {
                            grid-gap: 1rem;
                        }


                        @include lg-max {
                            display: block;
                        }


                        @include lg-min {
                            grid-template-columns: 1fr 1fr 1fr;
                            grid-gap: 5rem;

                            .Social-Icons {
                                display: flex;
                                align-items: flex-start;
                            }

                            //grid-template-columns: auto;
                        }

                        @include xl-min {
                            grid-template-columns: 170px 145px 205px 140px auto;
                            justify-content: space-between;

                            .Social-Icons {
                                grid-column-start: 2;
                                grid-column-end: 4;
                            }
                        }

                        .Logo {
                            img {
                                @include lg-max {
                                    height: 24px !important;
                                }
                            }
                        }

                        > * {
                            @include lg-max {
                                margin-bottom: 24px;
                            }
                        }

                        div {
                            &:first-child {
                                display: block;
                            }
                        }

                        > div {
                            details,
                            h3 {
                                color: #fff;
                                line-height: 32px;
                                font-size: 24px;
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                        }

                        .Social-Media {
                            display: flex;
                            align-items: center;
                            flex-wrap: nowrap;
                            margin-left: 24px;

                            li {
                                width: auto;
                                margin: 0 7px;

                                &:first-child {
                                    margin-left: 0;
                                }
                            }

                            &-Icon {
                                img {
                                    filter: invert(1);
                                }
                            }
                        }

                        @include lg-max {
                            --column-content-padding-logo: 24px 44px;
                        }
                    }
                }
            }

            &-Column {
                a {
                    --column-button-collor: #fff;
                    font-size: 14px;
                    text-transform: none;
                    font-weight: 400;

                    @include lg-max {
                        white-space: normal;
                    }

                    @include lg-max {
                        font-size: 16px;
                    }
                }
            }

            &-Copyright {
                --copyright-background-color: #e6f3f3;
                font-size: 12px;
                line-height: 24px;
                font-weight: 500;
                color: var(--mepal-third-color);
                text-transform: uppercase;

                &-Wrapper {
                    > div {
                        @include lg-max {
                            margin: 2px 0;
                        }
                    }

                    @include lg-max {
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0;
                    }
                }

                @include lg-max {
                    font-size: 16px;
                }
            }
        }

        &-CmsBlockWrapper {

            .Footer-Column_isNewsletter {
                background-color: var(--mepal-secondary-color);
                color: $white;


                .Field {
                    input {
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }
}
