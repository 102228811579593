.Footer {
    &-Copyright,
    &-Author {
        display: flex;
        align-items: center;
    }

    &-Copyright {
        @include mobile {
            flex-direction: column;
            justify-content: center;
        }
    }

    &-Author {
        margin-left: 12px;

        img {
            width: 90px;
        }
    }
}
