body {
    &.viallidesign {
        .HomepageCategories {
            @include desktop {
                row-gap: 2.1rem;
                column-gap: 1.7rem;
                grid-template-columns: repeat(3, 1fr);
            }

            @include tablet {
                grid-template-columns: repeat(2, 1fr);
            }

            &-Figure {
                margin-top: 3.5rem;
                height: auto;

                @include after-mobile {
                    margin-top: 0;
                    //height: 444px;
                }

                .Image {
                    display: block;
                    height: 0;
                    padding-bottom: 100%;

                    &-Image {
                        position: absolute;
                    }
                }
            }

            &-Button.Button {
                strong {
                    --margin: 1.8rem;

                    margin: var(--margin);
                    width: calc(100% - (var(--margin) * 2));
                    padding: 16px 0;
                    font-size: 1.4rem;
                    font-weight: 600;
                    letter-spacing: 0;

                    @include xl-min {
                        font-size: 1.6rem;
                        padding: 23px 0;
                    }

                    @include xxl-min {
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
}
