body {
    &.viallidesign {
        .CartItem {
            @media (max-width: 1199px) {

                &-Actions {
                    position: relative;
                }

                &-Price {
                    &_isLikeTable {
                        position: relative;
                    }
                }
            }
        }
    }
}
