body {
    &.viallidesign {
        .CookiePopup {
            &-Content {
                font-size: 11px;
                margin-bottom: 0;
            }
        }
    }
}

