/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../../../src/style/abstract/variables";
@import "../../../../../src/style/abstract/media";
@import "../../../../../src/style/abstract/button";
@import "../../../../../src/style/abstract/loader";
@import "../../../../../src/style/abstract/icons";
@import "../../../../../src/style/abstract/parts";
@import "../../../../../src/style/abstract/mixins";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../component/BlogRecentPosts/BlogRecentPosts.style";

:root {
    --load-more-button-background: #{$white};
    --load-more-button-color: #{$default-primary-base-color};
    --load-more-button-border: 2px solid #{$default-secondary-base-color};
}

.PostsListing {

    &-Wrapper {
        @include mobile {
            padding-top: 5px;
        }
    }

    &-Grid {
        @extend .BlogRecentPosts-List;
    }


    &-LoadMoreWrapper {
        padding-top: 40px;
        text-align: center;
        @include mobile {
            order: 2;
            width: 100%;
        }
        flex-basis: 100%;
    }

    & &-LoadMoreButton {
        margin: auto;
        color: var(--load-more-button-color);
        background-color: var(--load-more-button-background);
        border: var(--load-more-button-border);
        border-radius: 3px;
    }
}
