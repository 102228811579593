body {
    &.mepalmio {
        .Footer {
            &-Content {
                .CmsBlock-Wrapper {
                    .ContentWrapper {
                        @include xl-min {
                            grid-template-columns: 170px 145px 205px 180px 400px;
                        }
                    }
                }
            }
        }
    }
}
