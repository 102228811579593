body {
    &.viallidesign {
        .ProductList.ProductListWidget {
            @extend .ContentWrapper;

            --paddingInline: 14px;
            @include vialliListWrapper(
                var(--paddingInline),
                "title",
                "productList"
            );

            h2 {
                @extend .Widget-Title;

                grid-area: title;
                margin-top: 0;
                margin-bottom: 32px;
                padding-top: 35px;
                padding-bottom: 0;
                text-align: center;
                text-transform: unset;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: calc((100vw - 100%) * -0.5);
                    height: 250px;
                    width: 100vw;
                    background-color: $vialli-lightgray2;
                    z-index: -1;
                }

                @include desktop {
                    font-size: 60px;
                    line-height: 80px;
                    padding-top: 100px;
                    margin-bottom: 70px;
                    text-align: left;

                    &:after {
                        height: 400px;
                    }
                }
            }

            .ProductListPage {
                grid-area: productList;

                grid-template-columns: 1fr;

                @include desktop {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }
}
