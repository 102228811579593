body {
    &.viallidesign {
        .ExpandableContent-Heading {
            font-family: $vialli-secondary-font;
            font-weight: 400;
            font-size: 14px;
            color: $vialli-darkgray2;
        }

        .ExpandableContent-SubHeading {
            background-color: blue;
            color: $vialli-darkgray2;
            font-family: $vialli-secondary-font;
            font-size: 30px;
            margin: 8px 0 12px;
            font-weight: 600;
        }

        .ProductAttributes-Attributes {
            grid-gap: 0;
        }

        .ProductInformation-ExpandableContentContent {
            font-size: 14px;
            font-weight: 400;
            font-family: $vialli-base-font;
        }

        .ProductInformation-ExpandableContentHeading,
        span.ExpandableContent-Heading.ProductAttributes-ExpandableContentHeading {
            font-family: $vialli-secondary-font;
            font-size: 30px;
            font-weight: 600;

            @include desktop {
                margin-bottom: 16px;
            }

            @include mobile {
                font-size: 14px;
                font-family: $vialli-base-font;
                font-weight: 400;
                color: $vialli-darkgray2;
            }
        }

        .ProductAttributes-AttributeLabel,
        .ProductAttributeValue,
        .ProductAttributes-ValueLabel {
            height: 100%;
            padding: 12px;
        }

        .ProductAttributes-AttributeLabel:nth-of-type(even),
        .ProductAttributes-ValueLabel:nth-of-type(even) {
            background-color: $vialli-lightgray1;
            position: relative;
        }

        .ProductAttributes-ValueLabel {
            margin-bottom: 0;
        }

        .ProductAttributes-ValueLabel:nth-of-type(even)::before {
            content: "";
            position: absolute;
            left: -100%;
            width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem);
            height: 100%;
            top: 0;
            z-index: -1;
            background-color: $vialli-lightgray1;
        }

        .ExpandableContent-Button {
            @include mobile {
                align-items: center;
                flex-direction: unset;
                border: 3px solid #000;
                margin: 6px auto 0;
                width: 92%;
                padding: 0;
            }
        }

        .ExpandableContent {
            &:first-of-type {
                margin-top: 24px;
            }

            &:last-of-type {
                border: none;
            }
        }

        .ExpandableContent-Button_isContentExpanded {
            @include mobile {
                margin-bottom: 24px;
            }
        }

        .ContentWrapper {
            .TopWrapper {
                @include desktop {
                    padding: 0 0 220px;
                }

                .ProductGallery {
                    background-color: #fff;

                    @include desktop {
                        top: 75px;
                        transform: translateY(150px);
                        z-index: 1;

                        &::before {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 200%;
                            left: -100%;
                            top: -500px;
                            background-color: #fff;
                        }
                    }

                    &-SliderWrapper {
                        background-color: $white;

                        .Slider-Wrapper {
                            height: 94%;
                        }

                        .Slider-Crumbs {
                            bottom: 4px;
                        }
                    }

                    &-Additional {
                        width: 95%;
                    }
                }

                .Product-Information {
                    .ProductActions {
                        background-color: $vialli-lightgray2;
                        position: relative;
                        display: grid;

                        @include mobile {
                            padding: 8px 12px;
                        }

                        @include desktop {
                            &::before {
                                content: "";
                                position: absolute;
                                width: calc(100vw / 2);
                                height: 120%;
                                left: -40px;
                                top: -20%;
                                z-index: 0;
                                background-color: $vialli-lightgray2;
                            }


                            &-Qty {
                                font-weight: 400;
                                font-size: 16px;
                            }
                        }

                        &-AddToCartWrapper {
                            justify-content: flex-end;
                            flex-wrap: wrap;
                            row-gap: 8px;

                            .AddToCart {
                                background-color: $white;
                                color: $vialli-darkgray2;
                                border: none;
                                margin-left: 8px;
                            }

                            &-Qty {
                                margin-right: 0;
                                border: none;

                                button:disabled {
                                    background-color: $vialli-lightgray1;
                                    border: none;
                                }

                                button:not(:disabled) {
                                    background-color: $vialli-lightgray3;
                                    border: none;
                                }

                                input {
                                    font-weight: 400;
                                    border: none;
                                }
                            }

                            .ProductCompareButton .Button,
                            .ProductWishlistButton button {
                                border: none;
                                background-color: transparent;
                            }

                            .ProductCompareButton {
                                flex-basis: 50%;
                                margin-left: 0;
                                text-align: right;
                            }

                            .ProductWishlistButton {
                                margin-left: 0;
                            }
                        }

                        .ProductActions-ExtraCodesWrapper {
                            display: block;
                            text-align: right;
                            margin-top: 12px;
                            font-family: $vialli-base-font;
                            font-size: 24px;
                            color: $vialli-darkgray2;
                            order: 1;
                        }

                        .ProductActions-Section {
                            margin-top: 12px;
                            justify-content: flex-end;
                            border-top: none;
                            @include mobile {
                                display: flex;
                            }

                            h1 {
                                font-family: $vialli-secondary-font;
                                font-size: 40px;
                                line-height: 5rem;
                                text-align: right;
                                font-weight: 500;
                            }

                            .ProductActions-Stock {
                                color: $vialli-darkgray1;
                                font-family: $vialli-base-font;
                                text-transform: lowercase;
                                font-size: 18px;
                                font-weight: 400;
                            }
                        }

                        .ProductActions-PriceWrapper {
                            margin-top: 12px;
                            align-items: flex-end;

                            .ProductPrice {
                                font-family: $vialli-base-font;
                                font-size: 60px;
                                text-transform: lowercase;

                                @include mobile {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ProductLinks {
            &-Wrapper {
                --paddingInline: 14px;
                @include vialliListWrapper(
                        var(--paddingInline),
                        "title",
                        "productList"
                );

                h4 {
                    @extend .Widget-Title;

                    grid-area: title;
                    margin-top: 0;
                    margin-bottom: 32px;
                    padding-top: 35px;
                    padding-bottom: 0;
                    text-align: center;
                    text-transform: unset;
                    font-family: $vialli-secondary-font;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: calc((100vw - 100%) * -0.5);
                        height: 400px;
                        width: 100vw;
                        background-color: $vialli-lightgray2;
                        z-index: -1;
                    }

                    @include desktop {
                        font-size: 60px;
                        line-height: 80px;
                        padding-top: 100px;
                        margin-bottom: 70px;
                        text-align: left;
                    }
                }

                .ProductLinks-List {
                    grid-area: productList;

                    grid-template-columns: 1fr;

                    @include desktop {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    @include tablet {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}
