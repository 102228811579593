body {
    &.viallidesign {
        .ProductCard {
            margin-bottom: 48px;

            &-Link {
                height: 100%;
            }

            &-Picture {
                padding: 12px 12px 64% 12px;
            }

            &-Figure {
                background-color: $white;
                flex-grow: 0;

                .Image {

                }
            }

            &-AdditionalContent {


                &:not(:empty) {
                    margin: 12px 12px 0 12px;
                }

                .AddToCart {
                    width: 100%;
                    padding: 16px 26px;
                    font-size: 10px;
                    background: $vialli-darkgray1;
                    border-color: $vialli-darkgray1;
                }
            }

            &-ConfigurableOptions {
                display: none;
            }

            &-Brand {
                display: none;
            }

            &-Name {
                font-family: $vialli-secondary-font;
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                color: $vialli-darkgray3;
                margin: 0 0 30px;
                padding: 22px 12px 0;
                overflow: visible;
            }

            &-Content {
                padding-bottom: 0;
                justify-content: flex-end;
            }

            &-PriceWrapper {
                background: $white;
                flex-grow: 0;
            }

            &-Price {
                color: $vialli-darkgray3;
                font-family: $vialli-base-font;
                font-weight: bold;
                font-size: 16px;
                line-height: 32px;
                text-align: center;
                background: #ffffff;
                padding: 5px;
                border: 3px solid $vialli-darkgray3;

                margin: 0 12px 0;
                width: auto;

                @include desktop {
                    font-size: 20px;
                }
            }
        }
    }
}
