@media (min-width: 960px) {
    .ProductList.ProductListWidget {

        min-height: auto;

        .ProductListPage {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}
