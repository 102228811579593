/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #0a0a0a;
$default-primary-base-color: #f26323;
$default-primary-dark-color: #d1490c;
$default-primary-light-color: #f69e77;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #929292;
$default-secondary-light-color: #f8f8f8;
$font-muli: "Manrope", sans-serif;
$font-dosis: "Dosis", sans-serif;
$font-standard-size: 62.5%;

/*
*
* Vialli Design Variables
*
*/

//Colors
$vialli-lightgray1: #FAFAFA;
$vialli-lightgray2: #EBEBEB;
$vialli-lightgray3: #BABABA;
$vialli-darkgray1: #929296;
$vialli-darkgray2: #303033;
$vialli-darkgray3: #010105;

//Fonts
$vialli-base-font: "Montserrat", sans-serif;
$vialli-secondary-font: "PlayfairDisplay", serif;
