body {
    &.viallidesign {
        //Variables here are to move, not sure where yet.

        --content-wrapper-width: 100%;

        @include sm-min() {
            --content-wrapper-width: 540px;
        }

        @include md-min() {
            --content-wrapper-width: 720px;
        }

        @include lg-min() {
            --content-wrapper-width: 960px;
        }

        @include xl-min() {
            --content-wrapper-width: 1140px;
        }

        @include xxl-min() {
            --content-wrapper-width: 1390px;
        }


        --button-background: #{$vialli-darkgray3};
        --button-border: var(--button-background);
        --button-hover-background: #{$vialli-darkgray2};
        --button-hover-border: var(--button-hover-background);

        .Button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            letter-spacing: 2px;

            &_isHollow {
                background-color: $vialli-lightgray2;
                border-color: $vialli-lightgray2;

                &:hover {
                    background-color: $vialli-lightgray3;
                    border-color: $vialli-lightgray3;
                }
            }

            &_likeLink {
                letter-spacing: 0;
            }
        }

        p {
            font-size: 18px;
            line-height: 1.6;
        }


        main {
            @include mobile {
                margin-top: 0;
            }
        }
    }
}
