:root {
    --contact-form-page-elements-gap: 32px;
}

.ContactFormPage {
    margin-bottom: var(--contact-form-page-elements-gap);
    text-align: center;

    div {
        .Image_ratio_square {
            min-height: 40px;
            padding: 0 calc(50% - 20px);
            margin-bottom: 24px;

            .Image-Image {
                max-width: 40px;
                object-position: 0;
                height: auto;
                left: auto;
                display: block;
            }
        }

        p {
            max-width: 250px;
            margin: 0 auto 24px auto;
        }

        h4 {
            font-size: 18px;
            margin-bottom: 16px;
        }
    }

    @include after-mobile {
        display: grid;
        grid-gap: var(--contact-form-page-elements-gap);
        grid-template-columns: repeat(3, 1fr);
        @media (max-width: 960px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
