body {
    &.mepal {
        .ProductPage {
            &-Wrapper {

                .TopWrapper {
                    @include desktop {
                        grid-template-columns: 50% 50%;
                    }
                }
            }
        }
    }
}
