footer {
    &.Footer {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            li {
                margin: 0;
                padding: 1rem;

                a {
                    display: block;
                }

                .Image {
                    height: auto;

                    &_ratio_square {
                        padding-bottom: 0;
                    }
                }

                img {
                    width: 26px !important;
                    position: relative;
                }

                &::before {
                    content: none;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
