body {
    &.mepal {
        .Header {
            --header-background: var(--mepal-primary-color);
            --header-color: #fff;
            border-bottom: none;


            &_name_cart_overlay {
                .Header-Button_type_minicart,
                .Header-MinicartTitle {
                    --header-color: #fff;
                }
            }

            &-MinicartIcon {
                &:before,
                &:after {
                    --header-color: #fff;
                }
            }

            &-MinicartItemCount {
                background-color: var(--mepal-secondary-color);

                @include desktop {

                    position: absolute;
                    right: 10px;
                    top: -10px;
                    color: #fff;
                    border-radius: 100%;
                    font-weight: 700;
                    font-size: 14px;
                    padding: 0;
                    min-width: 20px;
                    min-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 5;
                }
            }

            &-MinicartTitle {
                --header-color: #fff;
            }
        }
    }
}
