body {
    &.mepal {
        .News {
            .List {
                list-style-type: none;
                display: grid;
                grid-auto-rows: max-content;
                grid-column-gap: 1rem;

                @include after-mobile {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include desktop {
                    grid-template-columns: repeat(3, 1fr);
                }

                &-Item {
                    padding: 0;
                    margin-bottom: 48px;
                    display: flex;
                    flex-direction: column;

                    .Image_ratio_square {
                        padding-bottom: 67.5%;
                    }

                    &::before {
                        content: none;
                    }

                    &-Content {
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        h3 {
                            color: var(--mepal-secondary-color);
                            margin-top: 16px;
                            line-height: 24px;
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 8px;

                            @include desktop {
                                line-height: 32px;
                                font-size: 24px;
                                margin-top: 24px;
                            }
                        }

                        p {
                            color: var(--mepal-third-color);
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 400;
                            margin-top: auto;

                            @include desktop {
                                font-size: 16px;
                                line-height: 32px;
                            }
                        }

                        .Link {
                            font-weight: 600;
                            color: var(--mepal-third-color);
                            display: inline-block;
                            margin-top: 8px;
                            font-size: 16px;
                            align-self: flex-end;

                            @include desktop {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
