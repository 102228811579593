body {
    &.viallidesign {
        .NoMatch {
            &-Wrapper {
                margin: 80px 0;
            }

            &-Button {
                background-color: transparent;
                color: $vialli-darkgray3;
                border: 2px solid $vialli-darkgray3;
            }
        }
    }
}
