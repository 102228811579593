body {
    &.viallidesign {
        &[data-page="o-marce"] {
            .CmsPage-Heading {
                display: none;
            }
        }

        .CmsPage {
            &-Wrapper {
                margin-top: 0;
            }

            &-Heading {
                margin: 0 -14px 24px -14px;
                font-size: 25px;
                line-height: 1.32;
                text-transform: none;
                font-family: $vialli-secondary-font;
                font-weight: normal;
                text-align: right;
                display: block;
                background-color: $vialli-lightgray2;

                padding: 24px 20px;

                @include desktop {
                    font-size: 60px;
                    margin: 0 0 32px 0;
                    padding: 20px 24px 24px;

                    &:after {
                        display: block;
                        position: absolute;
                        width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem);
                        background-color: $vialli-lightgray2;
                        content: '';
                        left: 100%;
                        top: 0;
                        bottom: 0;
                    }
                }
            }

            &-Content {
                //p,
                //span {
                //    font-size: 18px;
                //    line-height: 1.6;
                //}
                //
                //p {
                //    margin-bottom: 16px;
                //}
            }

            .GridItem {
                display: flex;
                background-color: $vialli-lightgray2;
                text-align: left;
                margin-bottom: 120px;

                &:last-child {
                    margin-bottom: 0 !important;
                }

                @include mobile {
                    flex-direction: column-reverse;
                    padding: 20px;
                    margin-left: -1.4rem;
                    margin-right: -1.4rem;
                }

                &:after {
                    display: block;
                    position: absolute;
                    width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem);
                    background: $vialli-lightgray2;
                    content: '';
                    left: 100%;
                    top: 0;
                    bottom: 0;
                }


                @include desktop {
                    &:nth-child(even) {

                        &:before {
                            display: block;
                            position: absolute;
                            width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem);
                            background: $vialli-lightgray2;
                            content: '';
                            right: 100%;
                            top: 0;
                            bottom: 0;
                        }


                        direction: rtl;

                        .GridItem-Image {
                            margin-top: -64px;
                            margin-right: -64px;
                        }

                        .GridItem-Content {
                            padding-left: 0;
                        }

                        &:after {
                            left: -100%;

                        }
                    }
                }

                &-Image {
                    flex: 1;
                    margin-top: auto;
                    margin-bottom: -64px;

                    .Image {
                        padding: 0;
                        height: auto;

                        img {
                            position: relative;
                        }
                    }
                }

                &-Content {
                    flex: 1;
                    @include desktop {
                        padding: 32px 48px;
                    }
                }

                &-Title {
                    text-transform: none;
                }

                &-Text {

                }
            }
        }
    }
}
