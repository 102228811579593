:root {
    --menu-offset-left: 25px;
    --menu-width: 400px;
    --menu-height: 50vh;
    --menu-grid-gap: 16px;
    --menu-item-caption-background: #fff;
    --menu-item-caption-color: #000;
    --menu-item-sub-level-caption-background: #fff;
    --menu-item-sub-level-caption-color: #000;
    --menu-desktop-subitem-arrow-color-sub-level: var(--menu-desktop-subitem-arrow-color);
}

.SharedTransition {
    display: none;
}

@include after-mobile {
    body {
        &::after {
            content: '';
            transition: background-color .15s linear;
            z-index: 1;
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            pointer-events: none;
            top: var(--header-height);
        }

        &.menuOverlay {
            overflow: hidden;

            &::after {
                background-color: rgba(26, 27, 28, .5);
                z-index: 11;
                pointer-events: auto;
            }
        }
    }
}
