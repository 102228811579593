body {
    &.mepal {
        .CategoryDetails {
            --category-details-background: transparent;
            color: var(--mepal-third-color);
            grid-template-columns: auto;

            &-Heading {
                margin-bottom: 24px;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                text-transform: none;
            }

            &-Description {
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
            }

            &-Picture {
                display: none;

                @include after-mobile {
                    display: block;
                    height: 275px;
                    max-width: var(--content-wrapper-width);
                    margin: 0 auto;

                    img {
                        margin-left: 12px;
                        margin-right: 12px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
