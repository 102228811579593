body {
    &.viallidesignen {
        .ProductCard-Content {
            .ProductCard-PriceWrapper {
                display: none !important;
            }
        }

        .ProductCard-AdditionalContent {
            .ProductActions-AddToCart {
                display: none !important;
            }
        }

        .ProductActions-PriceWrapper {
            display: none !important;
        }

        .Header-MyAccount {
            display: none !important;
        }

        .Header-Button_type_minicart {
            display: none !important;
        }

        .Header-CompareButtonWrapper {
            display: none !important;
        }

        .ProductActions-Stock {
            display: none !important;
        }

        .ProductActions-AddToCartWrapper {
            display: none !important;
        }

        .LangSwitcher-ViallidesignLangSwitcher {
            a {
                color: var(--header-color);
                &:hover {
                    color: var(--header-color);
                }
            }
        }
    }

    &.viallidesign {
        .Menu-MainCategories {
            .LangSwitcher-ViallidesignLangSwitcher {
                text-align: center;
                margin: 40px 0px;
                a {
                    background: white;
                    padding: 5px 10px;
                    color: var(--header-color);
                    &:hover {
                        color: var(--header-color);
                    }
                }
            }
        }
    }
}
