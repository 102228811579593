body {
    &.mepal {
        .Downloads-List {
            &-Item {
                &-Wrapper {
                    @include mobile {
                        align-items: center;
                    }
                }

                &-Title {
                    font-weight: 500;
                    line-height: 24px;
                    color: var(--mepal-third-color);
                }

                &-Button {
                    @extend .Button-Strong;
                    line-height: 24px;
                    padding: 6px 16px;
                }
            }
        }
    }
}
