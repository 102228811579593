body {
    &.viallidesign {

        .ProductList {
            nav:first-child {
                display: none;
            }

            .CategoryPagination {
                margin-top: 24px;
                gap: 4px;

                &-ListItem {
                    .CategoryPaginationLink {
                        font-size: 16px;
                        color: $vialli-lightgray3;
                    }

                    .CategoryPaginationLink_isCurrent {
                        font-size: 24px;
                        color: $vialli-darkgray3;
                        font-weight: 500;
                        transform: translateY(-1px);
                    }
                }

                &-Icon {
                    background-color: $vialli-lightgray3;

                    &::after {
                        width: 8px;
                        height: 8px;
                        top: 13px;
                        left: 15px;
                        transform: rotate(-45deg);
                        border-top: 2px solid $white;
                        border-left: 2px solid $white;
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}
