body {
    &.viallidesign {
        .Downloads {
            &-List {
                display: grid;
                column-gap: 16px;
                row-gap: 58px;
                flex-wrap: wrap;
                grid-template-columns: 1fr;
                margin-left: 0;
                margin-right: 0;

                @include desktop {
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                }

                &-Item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &,
                    &:last-child {
                        margin: 0;
                    }

                    &-Wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                    }

                    &-Image {
                        display: block;
                        width: 100%;
                        background-color: $vialli-lightgray2;
                    }

                    &-Title {
                        margin-top: auto;
                        padding-top: 14px;
                        margin-bottom: 18px;
                        color: #000;
                        font-size: 25px;
                        line-height: 33px;
                        font-weight: 400;
                        font-family: $vialli-secondary-font;
                        text-align: center;
                    }

                    &-Button {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 0;
                        font-size: 14px;

                        color: #000;
                        background-color: #fff;
                        border: 3px solid #000;
                        width: 100%;
                    }
                }
            }
        }
    }
}
