body {
    &.mepal {
        .ProductPrice {

            &_hasDiscount {
                display: block;
            }


            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-end;

            > span,
            ins,
            del {
                font-size: 24px;
                font-weight: 500;
            }

            > span {
                color: var(--mepal-third-color);
                //text-align: right;
                width: 100%;
            }

            ins {
                color: #da4b7a;

                + del {
                    order: -1;
                    margin-right: 16px;

                    @include desktop {
                        margin-right: 36px;
                    }
                }
            }

            del {
                padding-left: 0;
                font-weight: 300;
                color: #babccc;
            }
        }
    }
}
