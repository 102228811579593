.Awards {
    max-width: 920px;
    display: grid;
    margin: 54px auto;
    grid-template-columns: repeat(auto-fit, minmax(245px, 245px));
    grid-gap: 92px;
    text-align: center;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;

    &-Award {

        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .Image {
            margin-bottom: 16px;
        }
    }

    @include after-mobile {
        font-size: 24px;
    }
}
