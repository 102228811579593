body {
    &.viallidesign {
        .CartPage{
            &-Wrapper{

            }
            &-Heading {
                margin: 0 0 24px 0;
                font-size: 25px;
                line-height: 1.32;
                text-transform: none;
                font-family: $vialli-secondary-font;
                font-weight: normal;
                text-align: right;
                display: block;
                background-color: $vialli-lightgray2;
                height: auto;
                padding: 24px 20px;

                @include desktop {
                    font-size: 60px;
                    margin: 0 0 32px 0;
                    padding: 20px 24px 24px;

                    &:after {
                        display: block;
                        position: absolute;
                        width: 100%;
                        background-color: $vialli-lightgray2;
                        content: '';
                        left: 100%;
                        top: 0;
                        bottom: 0;
                    }
                }
            }
            &-Floating{

            }
            &-Static{

            }
            &-TableHead{
                border-bottom: none;
            }
        }
    }
}
