body {
    &.mepal {
        .Slider {
            &-Arrow {
                z-index: 10;

                &::after {
                    --slider-arrow-size: 7px;
                    border-width: 2px;
                    border-color: #fff #fff transparent transparent;
                }

                @include mobile {
                    display: none;
                }

                &_prev {
                    @include desktop {
                        left: 16px;
                    }
                }

                &_next {
                    @include desktop {
                        right: 16px;
                    }
                }
            }

            &-Crumb {
                --slider-crumb-background: transparent;
            }
        }
    }
}
