body {
    &.viallidesign {
        .ProductConfigurableAttributes {
            &-ExpandableContentContent {
                @include desktop {
                    margin: 10px 0 30px 0;
                }
            }
        }

        .ExpandableContent {
            &-Heading {
                font-family: $vialli-secondary-font;
                font-weight: normal;
                font-size: 20px;
                line-height: 27px;
                color: $vialli-darkgray2;
            }

            &-SubHeading {
                display: none;
            }

            &-Button {
                @include mobile {
                    padding: 20px;
                }
            }

            .ProductAttributeValue {
                display: block;
                padding: 0;
            }
        }
    }
}
