body {
    &.viallidesign {
        .Header_isCheckout {
            @include desktop {
                .Header-Nav {
                    height: 110px;

                    .Header-LogoWrapper {
                        margin: 0 auto;
                    }

                    .Header-MyAccount {
                        display: none;
                    }
                }
            }
        }

        .Checkout {
            @include desktop {
                margin-top: 172px;
            }

            &:before {
                display: none;
            }

            &-Wrapper {

            }

            &-Title,
            .CheckoutPage-Heading span {
                font-size: 32px;
                line-height: 1.33;
                font-family: $vialli-secondary-font;
            }

            &-Heading {
                font-family: $vialli-base-font;
                font-size: 20px;
                line-height: 1.33;
                margin-top: 56px;
            }

            .CheckoutOrderSummary-Header {
                flex-wrap: wrap;
            }

            .CheckoutShipping-Button {
                background-color: #29b551;
                border-color: #29b551;
            }
        }
    }
}
