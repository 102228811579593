body {
    &.mepal {
        .ProductListPage {

            @include desktop {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
