body {
    &.viallidesign[data-page="kontakt"] {
        --bg-gray-light: $vialli-lightgray2;

        .CmsPage {
            &-Content {
                @include desktop {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, auto);
                    grid-template-areas:
                          "name map"
                          "contact map";
                }

                h3 {
                    grid-area: name;
                    display: none;
                }

                .ContactFormPage {
                    grid-area: contact;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    @include desktop {
                        transform: translate(20px, -145px);
                        grid-gap: unset;
                    }

                    div {
                        display: grid;
                        grid-template-columns: 50px auto;
                        grid-template-rows: repeat(2, auto);
                        grid-gap: 25px;
                        grid-template-areas:
                              "icon title"
                              "icon content";

                        .Image {
                            grid-area: icon;
                        }

                        h4 {
                            grid-area: title;
                            text-align: left;
                            display: none;
                        }

                        p {
                            grid-area: content;
                            text-align: left;
                            margin: 0 !important;
                            grid-area: title/content;
                            white-space: nowrap;
                        }
                    }
                }

                > div {
                    grid-area: map;
                    @include desktop {
                        transform: translateY(-130px);
                    }
                }
            }

            &-Heading {
                text-align: left;
                font-size: 32px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background-color: $vialli-lightgray2;

                    @include desktop {
                        width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem + 100%);
                        height: 460px;
                    }

                    @include mobile {
                        height: 820px;
                    }
                }
            }
        }
    }
}
