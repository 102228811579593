body {
    &.mepalmio {
        .ProductSeries {

            &-Content {
                label {
                    display: none;
                }
            }
        }
    }
}

