body {
    &.mepal {
        .Widget {
            &-Title {
                --widget-title-color: var(--mepal-primary-color);
            }
        }
    }
}

