body {
    &.viallidesign {
        .Header {
            --header-logo-height: 50px !important;

            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;

            @include desktop {
                background-color: $vialli-lightgray1;
                border-top: 20px solid $vialli-darkgray2;
            }

            @include mobile {
                display: none;

                .scrollDisabled & {
                    display: block;
                }
            }

            &-LogoWrapper {
                --header-logo-height: 84px;


                @include sm-min {
                    --header-logo-width: 180px;
                }

                @include xxl-min {
                    --header-logo-width: 251px;
                }

                padding: 16px 0;
                box-sizing: content-box;

                .Logo {
                    height: auto;
                }

                .Image-Image {
                    width: 100%;
                    position: relative;

                }
            }

            &-Wrapper {
                margin-bottom: 141px;

                @include mobile {
                    margin-bottom: 85px;
                }
            }

            &-Title {
                font-size: 20px;
                line-height: 1;
            }

            @include desktop {
                &-Nav {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 1;
                    width: 100%;

                    height: 60px;
                    z-index: 1000;

                    .Header-MinicartItemCount {
                        left: 100%;
                        transform: translateX(-14px);
                        top: -5px;
                    }

                    > .Header-MyAccount,
                    > .Header-Button,
                    > .Header-CompareButtonWrapper {
                        padding: 0;
                        margin: 0;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        border: none;

                        .Header-MyAccountWrapper,
                        .Header-MinicartButtonWrapper {
                            padding: 0;
                            margin: 0;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .Header-CompareButtonWrapper {
                        padding-right: 0 !important;
                    }

                    .Header-MyAccount {
                        @include desktop {
                            padding-left: 20px;
                            padding-right: 15px;
                        }
                        @include mobile {
                            order: 1;
                        }

                        .Header-MyAccountTitle {
                            order: 2;
                        }

                        .MyAccountOverlay {
                            &:before {
                                right: 87px;
                            }
                        }

                        .Header-Button {
                            order: 1;
                            width: auto;
                            height: auto;
                            padding: 0 8px 0 0;

                            &:before {
                                background-image: url("/style/icons/user.svg");
                                height: 20px;
                                width: 20px;
                                border: none;
                                border-radius: 0;
                                display: block;
                                background-size: 20px;
                                position: relative;
                                margin: 0;
                                top: -1px;
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }

                    .Header-MinicartButtonWrapper {
                        @include mobile {
                            order: 2;
                            padding-left: 20px;
                            padding-right: 15px;
                        }
                        display: flex;
                        align-items: center;

                        .Header-MinicartTitle {
                            order: 2;
                        }

                        .Header-MinicartIcon {
                            order: 1;
                            margin-right: 8px;

                            &:before {
                                content: "";
                                background-image: url("/style/icons/sale.svg");
                                height: 20px;
                                width: 20px;
                                border: none;
                                display: block;
                                background-size: 20px;
                                position: relative;
                                top: -1px;
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }

                    .Header-CompareButtonWrapper {
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
