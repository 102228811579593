/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";
@import "../../style/abstract/mixins";

.ProductLinks {
    padding-bottom: 32px;

    &-List {
        //grid-gap: unset;

        @include after-mobile {
            grid-template-columns: repeat(4, 1fr);
        }

        li {
            padding-left: 0;
        }
    }
}

.react-multi-carousel {
    &-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
    }

    &-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        will-change: transform, transition;
    }

    &-item {
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }

    &-dot-list.Slider-Crumbs {
        bottom: 0;

        @include after-mobile {
            display: none;
        }

        li {
            padding: 0;
            margin: 0;

            &:last-child {
                margin-bottom: 0;
            }

            &.react-multi-carousel-dot--active {
                button {
                    background-color: #000;
                }
            }

            button {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                border: 1px solid #000;
                background-color: transparent;
                transition: background-color 300ms;
            }

            &:before {
                content: none;
            }
        }
    }
}

.react-multiple-carousel {

    &__arrow {
        position: absolute;
        outline: 0;
        transition: all .5s;
        border-radius: 35px;
        z-index: 1000;
        border: 0;
        background: rgba(0, 0, 0, 0.5);
        min-width: 43px;
        min-height: 43px;
        opacity: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &--left {
            left: calc(4% + 1px);

            &::before {
                right: 10px;
                transform: rotate(
                        -135deg
                );
            }
        }

        &--right {
            right: calc(4% + 1px);

            &::before {
                transform: rotate(45deg);
                left: 10px;
            }
        }

        &:hover {
            background: rgba(0, 0, 0, 0.8)
        }

        &:before {
            content: '';
            width: var(--slider-arrow-size);
            height: var(--slider-arrow-size);
            font-size: 36px;
            color: #fff;
            border: 1px solid;
            text-align: center;
            z-index: 2;
            position: absolute;
            border-color: #fff #fff transparent transparent;

        }
    }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .react-multi-carousel-item {
        flex-shrink: 0 !important
    }
    .react-multi-carousel-track {
        overflow: visible !important
    }
}
