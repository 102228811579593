body {
    &.viallidesign {
        .ProductListWidget {
            &-Page {
                grid-column-gap: 5.4rem;
                @include desktop {
                    .ProductCard-Price {
                        padding: 16px 5px;
                    }
                }
            }
        }
    }
}
