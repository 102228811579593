/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


.PostsDetails {

    &-Description {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        li:before {
            color: var(--mepal-primary-color);
        }

        p,
        li {
            font-size: 16px;
            line-height: 28px;
        }

        li {
            p {
                margin-bottom: 16px;
            }
        }

        p,
        ul,
        ol {
            margin-bottom: 32px;
        }
    }
}
