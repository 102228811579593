body {
    &.viallidesign {
        @include mobile {
            &[data-page='menu'] {
                .Breadcrumbs {
                    display: none;
                }
                #root{
                    background-color: $vialli-lightgray1;
                }
            }
            .MenuPage {
                background-color: $vialli-lightgray1;

                .Menu {
                    &-CompareLinkWrapper {
                        display: none;
                    }

                    &-ItemFigure {
                        height: auto;
                        padding: 14px 20px;
                        background-color: transparent;
                        display: block;
                    }

                    &-ItemCaption {
                        text-align: center;
                        font-size: 14px;
                        line-height: 1.28;
                        width: 100%;
                        text-transform: uppercase;
                    }

                    &-ItemList {
                        background-color: $vialli-lightgray1;
                    }

                    &-Link {
                        padding: 0;
                    }
                }
            }
        }
    }
}
