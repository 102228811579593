body {
    &.mepal {
        .Product-Information {

            .ProductReviews-Title,
            .ExpandableContent-Heading {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                color: var(--mepal-third-color);
                text-transform: uppercase;
                margin-bottom: 8px;
            }
        }

        .ProductInformation {

            &-Wrapper {
                @include desktop {
                    padding: 24px 48px;
                }
            }

            &-ExpandableContentContent {
                color: var(--mepal-third-color);
            }

            &-Attributes {
                grid-template-columns: 50% 40%;
                grid-gap: 4px;
            }
        }
    }
}
