/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../../src/style/abstract/variables";
@import "../../../../src/style/abstract/media";
@import "../../../../src/style/abstract/button";
@import "../../../../src/style/abstract/loader";
@import "../../../../src/style/abstract/icons";
@import "../../../../src/style/abstract/parts";
@import "../../../../src/style/abstract/mixins";

.special_status_product_list_item {
    display: block;
    position: absolute;
    width: 100%;

    .ProductActions & {
        position: relative;
    }

    div {
        font-size: 13px;
        font-weight: 700;
        width: auto;
        display: inline-block;
        padding: 2px 8px;
        color: #fff;
        z-index: 2;
        margin: 0 4px 4px 0;

        &.new {
            background-color: #0393ff;
        }

        &.promotion {
            background-color: #f6d735;
            color: #000;
        }

        &.bestseller {
            background-color: #10d210;
        }

        &.sale {
            background-color: #ff3a3a;
        }
    }
}
