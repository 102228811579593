@mixin wrapperPadding($paddingInline) {
    margin-left: auto;
    margin-right: auto;
    padding-left: $paddingInline;
    padding-right: $paddingInline;
}

body {
    &.viallidesign {
        .HomePage {
            .HeroSection {
                --imageOverlap: 100px;
                --gap: 36px;
                --paddingInline: 14px;

                display: grid;
                row-gap: var(--gap);
                grid-template-columns:
                    minmax(var(--paddingInline), 1fr)
                    minmax(auto, var(--content-wrapper-width))
                    minmax(var(--paddingInline), 1fr);
                grid-template-rows: auto auto auto auto 1fr;
                grid-template-areas:
                    "-  title --"
                    "- textPrimary --"
                    "- imageFirst --"
                    "- text --"
                    "- imageSecond --";

                @include desktop {
                    --gap: 50px;
                    --paddingInline: 12px;
                    --max-width-right-column: calc(
                        var(--content-wrapper-width) / 2.5
                    );
                    --max-width-left-column: calc(
                        var(--content-wrapper-width) -
                            var(--max-width-right-column)
                    );

                    grid-template-columns:
                        minmax(var(--paddingInline), 1fr)
                        minmax(auto, var(--max-width-left-column))
                        minmax(auto, var(--max-width-right-column))
                        minmax(var(--paddingInline), 1fr);
                    grid-template-areas:
                        "- ----- imageFirst imageFirst"
                        "- title imageFirst imageFirst"
                        "- textPrimary imageFirst imageFirst"
                        "- textPrimary imageSecond --"
                        "- text imageSecond --";
                }

                @include tablet {
                    --max-width: calc(var(--content-wrapper-width) / 2);

                    grid-template-columns:
                        minmax(var(--paddingInline), 1fr)
                        minmax(auto, var(--max-width))
                        minmax(auto, var(--max-width))
                        minmax(var(--paddingInline), 1fr);
                    grid-template-areas:
                        "- title title --"
                        "- textPrimary textPrimary --"
                        "- imageFirst imageSecond --"
                        "- text text --";
                }

                &-Title {
                    grid-area: title;

                    h2 {
                        margin-top: 0;
                        margin-bottom: 0;
                        text-transform: unset;
                    }
                }

                &-Text {
                    grid-area: text;

                    h3 {
                        margin-top: 0;
                        margin-bottom: 12px;
                        max-width: 674px;
                        font-family: $vialli-secondary-font;
                        font-weight: 400;
                        font-size: 25px;
                        line-height: 33px;
                    }

                    p {
                        margin-bottom: 23px;
                        max-width: 674px;
                        line-height: 1.6;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    @include desktop {
                        padding-right: var(--gap);

                        h3 {
                            margin-bottom: 14px;
                            font-size: 60px;
                            line-height: 80px;
                        }
                        p {
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }

                    &_primary {
                        --overflowSize: 200px;

                        grid-area: textPrimary;
                        display: grid;
                        grid-template-rows: min-content;
                        padding-top: var(--gap);
                        margin-bottom: calc(var(--overflowSize) * -1);
                        padding-bottom: var(--overflowSize);
                        color: $white;

                        &:before {
                            --left-space-after-content-wrapper-width: calc(
                                (100vw - 100%) * -0.5
                            );
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 100vw;
                            left: Min(
                                calc(var(--paddingInline) * -1),
                                var(--left-space-after-content-wrapper-width)
                            );
                            background-color: $vialli-darkgray2;
                        }

                        @include desktop {
                            margin-bottom: 0;
                            padding-bottom: var(--gap);

                            p {
                                margin-bottom: 28px;
                            }

                            &:before {
                                left: calc(
                                    (100vw - 100% - var(--imageOverlap)) * -1
                                );
                            }
                        }

                        @include tablet {
                            margin-bottom: calc(var(--overflowSize) * -1);
                            padding-bottom: var(--overflowSize);

                            &:before {
                                left: var(
                                    --left-space-after-content-wrapper-width
                                );
                            }
                        }
                    }
                }

                .Image_ratio_square {
                    padding-bottom: 0;
                }

                &-Image {
                    --imageBottomSpaceCompensation: -4px;

                    min-width: 300px;
                    margin-bottom: var(--imageBottomSpaceCompensation);

                    .Image {
                        height: auto;

                        &-Image {
                            position: relative;
                            object-fit: cover;
                        }
                    }

                    &_first {
                        grid-area: imageFirst;
                        overflow: hidden;

                        @include desktop {
                            max-height: 500px;
                            justify-self: flex-end;
                            align-self: flex-end;
                            width: calc(
                                100% - var(--imageOverlap) - var(--gap)
                            );
                        }

                        @include tablet {
                            max-height: unset;
                            width: calc(100% - (var(--gap) / 2));
                            justify-self: flex-start;
                            align-self: flex-start;
                        }
                    }

                    &_second {
                        grid-area: imageSecond;

                        @include tablet {
                            justify-self: flex-end;
                            width: calc(100% - (var(--gap) / 2));
                        }
                    }
                }
            }
        }
    }
}
