body {
    &.mepal {
        .Home-Slider-Wrapper {
            @include mobile {
                margin-left: -16px;
                margin-right: -16px;
            }
        }

        .homepage-slider {

            h2,
            p {
              padding : 0 24px;
            }

            h2 {
                color: #fff;
                display: inline-block;
                font-weight: 300;
                text-transform: none;
                font-size: 24px;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-bottom:0;
                margin-top: 0;
                background-color: rgba(117, 163, 162, 0.8);

                @include after-mobile {
                    font-size: 32px;
                    margin-top: 24px;
                }

                @include desktop {
                    font-size: 48px;
                    line-height: 72px;
                }

                strong {
                    display: inline-block;
                    font-size: 32px;

                    @include desktop {
                        font-size: 64px;
                    }
                }
            }


            .SliderWidget {

                &-Figure {
                    justify-content: flex-start;
                }

                &-Figcaption {
                    max-width: none;
                    left: auto;
                    text-align: left;
                    top: 50%;

                    &:not(:empty) {
                        padding: 32px;
                        background: transparent;
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: auto;

                        @include desktop {
                            align-items: center;
                            padding: 72px;
                        }
                    }

                    @include desktop {
                        left: 0;
                    }

                    p {
                        text-align: left;

                        @include desktop {
                            text-align: center;
                        }
                    }
                }
            }

            .HomepageCategories-Button {
                &.Button {
                    @extend .Button.Secondary;

                    display: inline-block;
                    width: auto;
                    font-size: 16px;
                    margin-top: 0;
                    text-transform: none;
                    padding: 8px 16px;

                    @include desktop {
                        margin-left: 14px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
