body {
    &.mepal {
        .Menu {
            &-ItemList {
                &_type_main {
                    > li > a {
                        color: #000;

                        @include desktop {
                            color: #fff;
                        }

                        @include desktop {
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 24px;
                            letter-spacing: 0;
                        }
                    }
                }
            }

            &-ItemFigure {
                &_type_main {
                    @include before-desktop {
                        --menu-item-figure-background: #E6F3F3;
                    }
                }
            }

            &-SubItemWrapper {
                flex-basis: 230px;

                @include desktop {
                    margin-right: 0;
                }
            }
        }
    }
}
