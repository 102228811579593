body {
    &.mepal {
        .ProductReviews {
            /*
             * Hide reviews
             */
            display: none;

            &-Title {
                margin-bottom: 8px;
            }
        }
    }
}
