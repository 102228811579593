body {
    &.mepal {
        .Button {
            transition: opacity .3s;
            border-radius: 0 0 12px 12px;
            border-color: var(--mepal-secondary-color);
            background-color: var(--mepal-secondary-color);
            color: #fff;
            font-weight: 600;

            &-Strong {
                //font-size: 20px;
                line-height: 40px;
                border-width: 2px;
                padding: 4px 12px;
                text-transform: none;
            }

            &:not([disabled]) {
                &:hover,
                &:focus {
                    border-color: var(--mepal-secondary-color);
                    background-color: var(--mepal-secondary-color);
                    opacity: .9;
                }
            }

            &.Primary {
                border-color: var(--mepal-secondary-color);
                background-color: transparent;
                color: var(--mepal-secondary-color);

                &:not([disabled]) {
                    &:hover,
                    &:focus {
                        border-color: var(--mepal-secondary-color);
                        background-color: transparent;
                        color: var(--mepal-secondary-color);
                    }
                }
            }

            &.Secondary {
                border-color: #fff;
                background-color: #fff;
                color: var(--mepal-primary-color);

                &:not([disabled]) {
                    &:hover,
                    &:focus {
                        border-color: #fff;
                        background-color: #fff;
                        color: var(--mepal-primary-color);
                    }
                }
            }

            &.Third {
                border-color: var(--mepal-primary-color);
                background-color: var(--mepal-primary-color);
                color: #fff;

                &:not([disabled]) {
                    &:hover,
                    &:focus {
                        border-color: var(--mepal-primary-color);
                        background-color: var(--mepal-primary-color);
                        color: #fff;
                    }
                }
            }

            &_likeLink {

                &:not([disabled]) {
                    &,
                    &:hover,
                    &:focus {
                        background-color: transparent;
                        border: none;
                        color: #000;
                    }
                }
            }
        }
    }
}
