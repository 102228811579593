body {
    &.mepal {
        .ProductList.ProductListWidget {
            min-height: auto;

            h2 {
                @extend .Widget-Title;
            }

            .ProductListPage {
                grid-template-columns: repeat(2, 1fr);

                @include after-mobile {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}
