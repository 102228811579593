/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../../../src/style/abstract/variables";
@import "../../../../../src/style/abstract/media";
@import "../../../../../src/style/abstract/button";
@import "../../../../../src/style/abstract/loader";
@import "../../../../../src/style/abstract/icons";
@import "../../../../../src/style/abstract/parts";
@import "../../../../../src/style/abstract/mixins";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../BlogRecentPosts/BlogRecentPosts.style";

.BlogPostCard {
    @extend .BlogRecentPosts-PostCard;

    &-ImageWrapper {
        display: block;
    }

    &-ContentWrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &-Details {
        @extend .BlogRecentPosts-Wrapper;
    }

    &-Title {
        @extend .BlogRecentPosts-PostTitle;
    }

    &-DescriptionWrapper {
        @extend .BlogRecentPosts-ShortContent;
    }

    &-Extra-Information {
        @extend .BlogRecentPosts-Extra-Information;
    }
}
