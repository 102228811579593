body {
    &.viallidesign {
        overflow-x: hidden;

        .CategoryPage {
            &-Wrapper {
                grid-template-rows: auto auto;
                padding-top: 0;

                @include desktop {
                    grid-template-columns: 400px auto;
                }
            }

            &-ProductListWrapper {
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 2;

                @include mobile {
                    padding-top: 58px;
                }

            }

            &-Miscellaneous {
                position: relative;
                padding: 0 20px;
                min-height: auto;
                margin-top: 20px;

                @include mobile {
                    top: 0;
                    display: block;
                    border: none;

                    .hideOnScroll & {
                        transform: none;
                    }
                }

                @include desktop {
                    display: none;
                }

                .CategorySort {
                    display: none;
                }
            }

            &-Filter {
                background: $vialli-lightgray2;
                color: $vialli-darkgray3;
                text-align: center;
                width: 100%;

                &:before,
                &:after {
                    display: none;
                }
            }

            &-ItemsCount {
                display: none;
            }
        }
    }
}
