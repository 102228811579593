body {
    &.viallidesign {
        .homepage-slider {
            --paddingInline: 14px;
            --max-width: var(--content-wrapper-width);
            --height: 568px;

            --desktopFigcaptionBgWidth: 70%;
            --desktopFigcaptionBgMaxWidth: 512px;

            @include md-min {
                --paddingInline: 12px;
                --height: 100%;

            }

            @media (max-height: 700px) and (min-width: 1280px) {
                --height: calc(100vh - 160px);
            }

            @media (max-height: 400px) and (min-width: 1280px) {
                --height: 400px;
            }


            .SliderWidget {
                &-Figure {
                    overflow: hidden;
                }

                &-Figcaption {
                    top: unset;
                    transform: unset;
                    bottom: 44px;
                    padding: 45px var(--paddingInline) 35px;
                    text-align: left;
                    color: $white;
                    background: rgba($vialli-darkgray2, 0.5);
                    max-width: 100%;

                    @include mobile {
                        & > * {
                            width: var(--content-wrapper-width);
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }

                    @include lg-min {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        top: 0;
                        bottom: 0;
                        margin-left: auto;
                        margin-right: auto;
                        max-width: var(--max-width);
                        padding: 0 var(--paddingInline);
                        background: unset;
                        &:before {
                            --paddingRight: 50px;
                            --leftMarginAutoWidth: (100vw - 100%) * 0.5;

                            top: 0;
                            content: "";
                            position: absolute;
                            background: rgba($vialli-darkgray2, 0.5);
                            width: calc(
                                (var(--leftMarginAutoWidth)) +
                                var(--desktopFigcaptionBgWidth) +
                                var(--paddingRight)
                            );
                            max-width: calc(
                                var(--desktopFigcaptionBgMaxWidth) +
                                var(--paddingRight) +
                                var(--leftMarginAutoWidth)
                            );
                            left: calc((100vw - 100%) * -0.5);
                            height: 100%;
                        }
                    }
                }

                &-FigureImage {
                    min-height: var(--height);
                    padding-bottom: 40%;

                    @media (max-height: 700px) and (min-width: 1280px) {
                        padding-bottom: 0;
                    }

                    .Image-Image {
                        position: absolute;
                    }
                }
            }

            h2 {
                @include multiline-ellipsis(4);
                margin-top: 0;
                margin-bottom: 10px;
                text-transform: unset;

                @include lg-min {
                    @include multiline-ellipsis(3);
                    margin-bottom: 20px;
                    max-width: var(--desktopFigcaptionBgMaxWidth);
                }
            }

            h4,
            p {
                @include multiline-ellipsis(5);
                font-size: 14px;
                font-weight: 300;

                @include lg-min {
                    max-width: var(--desktopFigcaptionBgMaxWidth);
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            h4 {
                margin-top: 0;
                margin-bottom: 4px;
                line-height: 28px;
            }

            p {
                padding-bottom: 14px;
                line-height: 22px;

                @include lg-min {
                    padding-bottom: 16px;
                }

                &:last-child {
                    grid-area: button;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }

            .HomepageCategories-Button {
                &.Button {
                    margin: 0;
                    padding: 16px 26px;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 2.8px;
                    width: auto;
                    background-color: $vialli-lightgray2;
                    color: $vialli-darkgray2;

                    @include xl-min {
                        padding: 26px 56px;
                        font-size: 18px;
                    }
                }
            }

            .Slider {
                &-Wrapper {
                    grid-area: slider;
                }

                &-Crumbs {
                    bottom: 12px;
                    padding-left: 33px;
                    padding-right: 25px;
                    grid-template-columns: 1fr auto;
                    grid-auto-flow: column;
                    align-items: center;
                    gap: 25px;

                    &:before {
                        content: "";
                        height: 3px;
                        width: calc(100% - 14px);
                        background-color: $white;

                        @include lg-min {
                            justify-self: flex-end;
                            width: calc(50% - 14px);
                        }
                    }
                }

                &-Crumb {
                    --size: 10px;
                    --opacity: 0.5;

                    height: var(--size);
                    width: var(--size);
                    border-radius: 0;
                    border: none;
                    background-color: $white;
                    opacity: var(--opacity);

                    &_isActive {
                        --size: 20px;
                        --opacity: 1;
                    }
                }
            }
        }
    }
}
