$backgroundOverflow: 20px;

@mixin postCardBackground($overflowSize, $height: 100%) {
    &:before {
        content: "";
        position: absolute;
        padding: 0;
        left: -$overflowSize;
        bottom: 0;
        height: $height;
        width: calc(100% + (#{$overflowSize} * 2));
        background-color: $vialli-lightgray2;
    }
}

body {
    &.viallidesign {
        .BlogRecentPosts {
            &-Wrapper {
                --paddingInline: 14px;
                @include vialliListWrapper(
                    var(--paddingInline),
                    "title",
                    "list"
                );
            }

            &-Title {
                grid-area: title;
                margin-top: 50px;
                margin-bottom: 22px;
                font-family: $vialli-secondary-font;
                text-align: center;

                @include desktop {
                    text-align: right;
                    font-size: 60px;
                    line-height: 80px;
                }
            }

            &-List {
                grid-area: list;
                column-gap: 16px;
                row-gap: 50px;

                @include mobile {
                    grid-template-columns: 1fr;
                }

                @include desktop {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            &-PostCard {
                position: relative;
                padding: 0 $backgroundOverflow 27px;

                .BlogRecentPosts-Wrapper {
                    display: flex;
                    @include postCardBackground($backgroundOverflow);
                }
            }

            &-Extra-Information {
                display: none;
            }

            &-PostTitle {
                margin-top: 37px;
                margin-bottom: 23px;
                font-size: 20px;
                text-align: center;
                line-height: 32px;
                font-weight: 700;
                font-family: $vialli-secondary-font;
                color: $vialli-darkgray3;

                @include desktop {
                    margin-top: 34px;
                    margin-bottom: 17px;
                }

                h3 {
                    margin: 0;
                }
            }

            &-ShortContent {
                margin-bottom: 0;
                text-align: center;
                font-size: 14px;
                line-height: 22px;
            }

            &-ImageWrapper {
                @include postCardBackground($backgroundOverflow, 50%);

                .Image {
                    &-Image {
                        position: absolute;
                    }
                }
            }

            &-ReadMore {
                @extend .ProductCard-Price;
                transform: translateY(50%);
                font-weight: 600;
                width: 100%;
                margin: 0;
            }
        }
    }
}
