body {
    &.mepal {
        .ProductCard {
            &-Figure {
                flex-grow: 0;
            }

            &-Link {
                height: 100%;
            }

            &-Content {
                flex-grow: 1;
            }

            &-Name {
                color: var(--mepal-third-color);
                line-height: 16px;
                font-weight: 500;
                margin-bottom: 24px;

                @include desktop {
                    line-height: 32px;
                    font-size: 18px;
                }
            }

            &-Price {
                display: flex;
                order: 3;
            }

            &-Picture {
                padding-bottom: 100%;
            }
        }
    }
}
