body {
    &.mepal {
        .CmsPage-Wrapper {

            @include after-mobile {
                margin-top: 0;
            }
        }

        .CmsPage-Heading {
            @include mobile {
                display: block;
            }
        }
    }
}
