body {
    &.mepal {

        .ProductPage-Wrapper {
            .Slider {

                &-Arrow {
                    &:after {
                        --slider-arrow-size: 16px;
                        border-width: 1px;
                        border-color: #000 #000 transparent transparent;
                    }

                    &_prev {
                        @include desktop {
                            left: 0;
                        }
                    }

                    &_next {
                        @include desktop {
                            right: 0;
                        }
                    }
                }

                &-Crumbs {
                    grid-gap: 16px;
                }

                &-Crumb {
                    width: 8px;
                    height: 8px;
                    --slider-crumb-color: var(--mepal-secondary-color);
                }
            }
        }
    }
}
