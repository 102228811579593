body {
    &.viallidesign {

        .CategoryPage {
            &-CategoryExtraDescription {
                text-align: right;
            }
        }

        .CategoryDetails {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 1;

            display: block;
            text-align: right;

            background: transparent;
            margin-bottom: 0;


            &-Description {
                padding: 0;

                @include desktop {

                    display: grid;
                    grid-template-rows: auto auto;
                    grid-template-columns: 400px 1fr;

                    &:before {
                        display: block;
                        content: '';
                        background: $vialli-lightgray1;
                        box-sizing: content-box;
                        padding: 0 24px 0 90px;
                    }
                }
            }

            &-Heading {
                margin: 0;
                font-size: 25px;
                line-height: 1.32;
                text-transform: none;
                font-family: $vialli-secondary-font;
                font-weight: normal;
                padding: 20px 20px 24px;

                background: $vialli-lightgray2;

                + .CategoryDetails-Description {
                    display: block;
                    padding: 50px 20px;
                    margin: 0;
                    text-align: left;
                    font-size: 12px;
                    line-height: 1.5;
                    font-weight: normal;

                    p {
                        font-size: 12px;
                        line-height: 1.5;
                    }
                }

                @include desktop {
                    font-size: 60px;
                    margin: 0;
                    padding: 60px 20px 50px 0;
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 1;

                    &:after {
                        display: block;
                        position: absolute;
                        width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem);
                        background: $vialli-lightgray2;
                        content: '';
                        left: 100%;
                        top: 0;
                        bottom: 0;
                    }

                    + .CategoryDetails-Description {
                        grid-column-start: 2;
                        grid-column-end: 2;
                        grid-row-start: 2;
                        grid-row-end: 2;
                        display: none !important;


                        margin: 60px 0 60px 60px;
                        text-align: right;

                        font-size: 18px;
                        line-height: 1.5;

                        p {
                            font-size: 18px;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
}
