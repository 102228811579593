body {
    &.viallidesign {
        --buttonSize: 80px;

        .NewsletterSubscription {
            .FieldForm {
                &-Fieldset {
                    max-width: 387px;

                    @include desktop {
                        max-width: 585px;
                    }
                }

                &-Fields {
                    max-width: 577px;

                    input {
                        border: none;
                        font-size: 14px;
                        line-height: 28px;
                        padding: 11px 16px 11px 30px;

                        &::placeholder {
                            color: $vialli-darkgray3;
                        }

                        @include desktop {
                            padding: 12px 20px;
                            font-size: 16px;
                        }
                    }
                }

                &-Button {
                    --arrowSize: 17px;

                    width: var(--buttonSize);
                    border: none;
                    background-color: $vialli-lightgray2;

                    @include arrow(var(--arrowSize), 45%);

                    &:after {
                        content: unset;
                    }

                    @include desktop {
                        --buttonSize: 80px;
                        --arrowSize: 20px;
                    }
                }
            }

            .Field-Message {
                margin-top: 5px;
                font-size: 14px;
                line-height: 28px;
                width: calc(100% + var(--buttonSize));

                @include desktop {
                    margin-top: 10px;
                    font-size: 20px;
                }
            }
        }
    }
}
