/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --homepage-button-background: #fff;
    --homepage-button-color: #000;
}

.HomepageCategories {
    @include desktop {
        grid-gap: 1rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    &-Figure {
        margin-top: 1rem;

        @include after-mobile {
            margin-top: 5px;
            height: 400px;
        }

        .Image {
            height: 100%;
            display: block;
            padding-bottom: 0;

            @include mobile {
                height: 0;
                padding-bottom: 56.5%;
            }

            &-Image {
                object-fit: cover;
            }
        }
    }

    &-Figcaption {
        --button-background: transparent;
        --button-hover-background: transparent;
        --button-hover-border: transparent;
        --button-padding: 13px 40px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        @include mobile {
            --button-padding: 5px 0;
        }

        &_isPlaceholder {
            padding-bottom: 31px;
        }
    }

    &-Button.Button {
        font-weight: 300;
        width: 100%;
        height: 100%;
        border: none;

        &:hover {
            border: none;

            strong {
                background-color: var(--homepage-button-color);
                color: var(--homepage-button-background);
            }
        }

        strong {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 12px 0;
            background-color: var(--homepage-button-background);
            color: var(--homepage-button-color);
            font-size: 1rem;
            opacity: 0.82;
            transition: 0.3s;
            text-align: center;

            @include after-mobile {
                font-size: 1.3rem;
            }
        }
        @include mobile {
            text-align: left;
            width: 100%;
        }
    }

    a {
        display: block;

        + a {
            margin-left: 0;
        }
    }
}
