:root {
    --widget-title-color: #000;
}

body {
    .Widget {
        &-Title {
            font-weight: 300;
            font-size: 32px;
            line-height: 40px;
            padding-top: 32px;
            padding-bottom: 24px;
            color: var(--widget-title-color);
            text-transform: none;
            margin-bottom: 0;
            margin-top: 0;

            &.Reverse {
                color: #fff;
            }

            @include after-mobile {
                font-size: 48px;
                padding-bottom: 40px;
            }

            @include desktop {
                font-weight: 300;
                font-size: 64px;
                line-height: 72px;
            }
        }
    }

    .CmsPage-Heading {
        @extend .Widget-Title;
        padding: 24px 0;

        @include desktop {
            padding: 40px 0;
        }
    }

    .BlogRelatedPosts-Title,
    .BlogRecentPosts-Title,
    .PostsDetails-Title {
        @extend .Widget-Title;
    }
}

