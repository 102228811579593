body {
    &.viallidesign[data-page="do-pobrania"] {
        --bg-gray-light: $vialli-lightgray2;

        .CmsPage {
            &-Content {
                .Downloads-List {
                    &-Item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    &-Item-Title {
                        text-align: center;
                    }

                    &-Item-Button {
                        color: #000;
                        background-color: #fff;
                        border: 3px solid #000;
                        width: 100%;
                    }
                }
            }
        }
    }
}
