body {
    &.viallidesign {
        .CategoryProductList {
            top: 0;

            &-Page {

                @include tablet {
                    grid-template-columns: 1fr;
                }

                @include mobile {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}
