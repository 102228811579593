body {
    &.viallidesign {
        .Field_type_checkbox {
            align-items: center;
        }

        .FieldForm {
            .Field-Label {
                @include after-mobile {
                    min-width: calc(100% - 40px;);
                }
            }

        }

        .Field [type='checkbox'],
        .Field [type='radio'] {
            + label {
                width: 30px;
                height: 30px;

                &:after {
                    left: 9px;
                    top: 13px;
                }
            }

            &:checked {
                + label {
                    background-color: $vialli-darkgray2;
                    border-color: $vialli-darkgray2;
                }
            }
        }
    }
}
