/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../../../src/style/abstract/variables";
@import "../../../../../src/style/abstract/media";
@import "../../../../../src/style/abstract/button";
@import "../../../../../src/style/abstract/loader";
@import "../../../../../src/style/abstract/icons";
@import "../../../../../src/style/abstract/parts";
@import "../../../../../src/style/abstract/mixins";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --posts-details-title-size: 28px;
}

.PostsDetails {
    margin-bottom: 144px;

    &-ColumnWrapper {
        display: grid;


        @include after-mobile {
            grid-template-columns: 2fr 1fr;
            grid-gap: 140px;
        }
    }

    &-Content {
        margin-top: 32px;
    }

    &-Wrapper {
        display: grid;

        @include after-mobile {
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0 140px;
        }
    }

    &-Title {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    &-ColumnWrapper {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;

        @include after-mobile {
            grid-column-end: -1;
        }
    }


    &-Description {
        text-align: justify;


        p,
        li {
            font-size: 16px;
            line-height: 28px;
        }

        p,
        ul,
        ol {
            margin-bottom: 32px;
        }

        li {
            &:before {
                font-size: 20px;
            }
        }


        .Image {

            &_ratio_square {
                padding-bottom: 67.5%;
            }

        }
    }

    &-Extra-Information {
        display: flex;
        justify-content: space-between;
        //align-items: flex-start;
        //flex-direction: column;
        padding: 32px 0 16px 0;
        margin: 32px 0 16px 0;
        font-size: 16px;
        color: #000;
        border-top: 1px solid #000;


        @include after-mobile {
            flex-direction: row;
            align-items: center;
        }
    }


    &-Iframe {
        margin: 0 auto 1.2rem;
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            height: 100% !important;
            left: 0;
            position: absolute;
            top: 0;
            width: 100% !important;
        }
    }
}
