/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


.BlogRecentPosts {


    &-PostCard {
        display: flex;
        flex-direction: column;
        padding: 0;
        list-style-type: none;


        .Image-Image {
            object-fit: cover !important;
        }

        a {

            &:link,
            &:visited,
            &:focus,
            &:hover {
                text-decoration: none;
            }
        }

        &,
        &:last-child {
            margin: 0;
        }

        &:before {
            content: none;
        }

        &.CurrentPost {
            display: none;
        }
    }

    &-Wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &-List {
        display: grid;
        grid-gap: 42px 8px;
        grid-template-columns: 1fr;
        margin-bottom: 50px;

        &:not(.Column) {

            @include after-mobile {
                grid-gap: 96px 16px;
                grid-template-columns: repeat(2, 1fr);
            }

            @include desktop {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }


    &-PostTitle {
        margin-top: 12px;
        font-weight: 600;
        flex: 1;
        margin-bottom: 8px;

        body & {
            line-height: 32px;
            font-size: 24px !important;
        }
    }

    &-PostTitlePlaceholder {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-ShortContent {

        p {
            font-size: 16px;
            line-height: 32px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }

    &-Extra-Information {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        font-size: 14px;

        &,
        a {
            color: #000;
        }
    }

    &-ReadMore {
        margin-top: auto;
        color: #000;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        display: inline-block;
        align-self: flex-end;
        text-decoration: underline;
    }
}
