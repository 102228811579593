@font-face {
    font-family: "Montserrat";
    src: url("/style/fonts/Montserrat-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/style/fonts/Montserrat-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/style/fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/style/fonts/Montserrat-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "PlayfairDisplay";
    src: url("/style/fonts/PlayfairDisplay-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "PlayfairDisplay";
    src: url("/style/fonts/PlayfairDisplay-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "PlayfairDisplay";
    src: url("/style/fonts/PlayfairDisplay-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}
