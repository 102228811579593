body {
    &.mepal {
        .Field {
            [type='checkbox'],
            [type='radio'] {

                + label {
                    border-color: #BABCCC;
                    border-radius: 2px;
                }

                &:checked {
                    + label {
                        border-color: var(--mepal-secondary-color);
                        background-color: var(--mepal-secondary-color);
                    }
                }
            }

            &_type {
                &_number {

                    &,
                    &.ProductActions-Qty {

                        @include after-mobile {
                            border-radius: 0 0 12px 12px;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            order: 0;
                        }
                    }
                }
            }
        }
    }
}
