@mixin heading-text {
    font-family: $vialli-secondary-font;
    color: $vialli-darkgray3;
}

body {
    &.viallidesign {
        .PostsDetails {

            &-Wrapper {
                @include mobile {
                    display: block;
                }
            }

            &-Title {
                @include heading-text;
                font-size: 28px;

                @include tablet {
                    font-size: 26px;
                }

                @include desktop {
                    font-size: 60px;
                }

                @include mobile {
                    line-height: unset;
                }
            }

            &-Content {
                h2 {
                    @include heading-text;
                    font-size: 40px;
                    line-height: unset;
                    text-align: left;
                }

                h3 {
                    @include heading-text;
                    font-size: 2.8rem;
                }
            }

            &-Extra-Information {
                display: none;
            }

            &-RelatedPosts {
                background-color: $vialli-lightgray2;
                position: relative;

                .BlogRelatedPosts {
                    &-Title {
                        padding: 0 0 24px 32px;
                        font-family: $vialli-secondary-font;
                        font-size: 30px;
                        @include mobile {
                            margin-top: 20px;
                        }
                    }

                    &-PostsWrapper {
                        padding-left: 32px;

                        .BlogPostCard {
                            &-Extra-Information {
                                display: none;
                            }

                            &-Title {
                                font-family: $vialli-secondary-font;
                                color: $vialli-darkgray2;
                                font-size: 20px;
                                font-weight: 500;
                            }

                            &-DescriptionWrapper {
                                font-family: $vialli-base-font;
                                color: $vialli-darkgray2;
                                font-size: 16px;
                            }

                            .BlogRecentPosts-ReadMore {
                                font: normal normal 600 16px/28px $vialli-base-font;
                                letter-spacing: 3.2px;
                                color: $vialli-darkgray2;
                                text-transform: uppercase;
                                opacity: 1;
                                border: none;
                                background-color: transparent;
                                text-align: left;

                                &::after {
                                    content: '\002794';
                                    font-weight: 300;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }

                &::before, &::after {
                    content: '';
                    background-color: $vialli-lightgray2;
                    position: absolute;
                    z-index: -1;
                }

                &:before,
                &:after {
                    width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem + 100%);
                    height: 100%;
                    top: 0;
                    left: 0;
                }


                &::before {
                    @include desktop {
                        top: -100%;
                    }

                    @include mobile {
                        left: -10%
                    }
                }
            }
        }
    }
}
