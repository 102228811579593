/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --input-border-color: #e0e0e0;
    --input-background-color: #fff;
    --input-padding: 13px 20px;
}

.ContactForm .Field_type_checkbox {
    flex-direction: row;
}

input,
textarea,
select {
    --input-color: var(--primary-base-color);

    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    padding: var(--input-padding);
    appearance: none;
    caret-color: var(--primary-base-color);

    &:focus {
        border: 1px solid var(--input-color);
    }

    &:not(:first-of-type) {
        margin-top: 1rem;
    }
}

textarea {
    width: 25rem;
}

[type='number'] {
    width: var(--input-type-number-width);
    font-weight: 700;
    padding-right: 0;
    -moz-appearance: textfield;
    padding-left: 0;
    text-align: center;

    &:focus {
        border: 1px solid var(--input-border-color);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}

.Field {
    &_type {
        &_number {
            button {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                $size:40px;

                border: 1px solid var(--input-border-color);
                width: $size;
                font-size: 1.5rem;

                &:disabled {
                    cursor: not-allowed;
                }

                .ProductActions & {
                    &:first-child {
                        border-right: none;
                    }

                    &:last-child {
                        border-left: none;
                    }
                }
            }
        }
    }
}
