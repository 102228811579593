body {
    &.mepal {
        .ProductActions {

            @include mobile {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
            }

            @include desktop {
                padding: 24px 48px;
            }

            &-AddToCartWrapper {
                width: 100%;
            }

            &-Title {
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
                color: var(--mepal-primary-color);
                margin-bottom: 16px;
            }

            &-Price {

                ins,
                > span {
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 40px;
                }
            }

            &-Section {

                @include mobile {
                    border-top: none;
                }

                &_type {
                    &_sku {
                        display: inline-flex;
                        flex-basis: auto;
                        order: 6;
                        align-items: center;
                        color: #BABCCC;
                        font-weight: 400;

                    }

                    &_customizable_options {
                        &:empty {
                            padding: 0;
                        }
                    }
                }
            }

            &-Qty {

                @include desktop {
                    + .ProductActions-AddToCart {
                        margin-left: 24px;
                    }
                }
            }

            &-ExtraCodesWrapper {
                order: 20;

                @include mobile {
                    width: 100%;
                    text-align: center;
                }

                &:not(:empty) {
                    margin-top: 8px;
                    margin-bottom: 32px;
                }
            }

            &-EanCode,
            &-ProductCode {
                font-size: 14px;
                line-height: 24px;
                color: var(--mepal-third-color);

                > span {
                    font-weight: 500;
                }
            }

            &-CustomOptionsWrapper {
                order: 21;
                width: 100%;

                &:not(:empty) {
                    margin: 24px 0;

                    @include after-mobile {
                        margin: 56px 0;
                    }
                }

                .CapacitySelect-Select,
                .ColorSelect-Select {

                    @include mobile {
                        text-align: left;
                        padding: 0 12px;
                        max-width: 400px;
                        margin: 12px 0;
                    }

                    &-Title,
                    > label {
                        font-weight: 600;
                        font-size: 16px;
                        color: var(--mepal-third-color);
                        margin-bottom: 8px;
                    }
                }

                .CapacitySelect-Select {

                    .FieldSelect {
                        max-width: 168px;


                        &-Select {
                            border-radius: 0 0 12px 12px;

                            &_isExpanded {
                                border-radius: 0;
                            }
                        }
                    }
                }

                .ColorSelect-Select {

                    a {
                        display: inline-block;
                        margin: 0;

                        &:hover {
                            &:before {
                                content: attr(title);
                                padding: 4px 8px;
                                color: #000;
                                position: absolute;
                                left: 45%;
                                -webkit-transform: translateX(-50%);
                                transform: translateX(-50%);
                                top: -29px;
                                white-space: nowrap;
                                z-index: 20;
                                border-radius: 0 0 5px 5px;
                                border: 1px #d6d7e1 solid;
                                background: #fff;
                                font-size: 15px;
                            }
                        }

                        @include mobile {
                            &:last-child {
                                data {
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                    data {
                        margin: 8px 12px 8px 0;


                    }
                }
            }
        }
    }
}
