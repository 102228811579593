body {
    &.viallidesign {
        .MyAccountOverlay {

            &-Checkbox {
                flex-direction: row;
                justify-content: normal;
                right: 0;
            }
        }
    }
}


