body {
    &.viallidesign {
        .HomePage {
            .HomepageCategoriesWrapper {
                h2 {
                    text-align: center;
                    margin-top: 50px;
                    margin-bottom: 24px;
                    text-transform: unset;

                    @include desktop {
                        margin-top: 100px;
                        margin-bottom: 29px;
                        grid-column: 1 / -1;
                        text-align: right;
                    }
                }
            }
        }
    }
}

//            &-Header {
//            }
//    text-align: center;
//    margin-top: 50px;
//    margin-bottom: 24px;
//
//    @include desktop {
//        margin-top: 100px;
//        margin-bottom: 29px;
//        grid-column: 1 / -1;
//        text-align: right;
//    }
//}
