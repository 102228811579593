body {
    &.viallidesign {
        .Footer {
            .Footer-Wrapper {
                .Footer-Column {
                    .Footer-Content {
                        .CmsBlock-Wrapper {
                            width: 100%;
                            background-color: $vialli-darkgray2;

                            .ContentWrapper {
                                padding-top: 80px;
                                color: $white;

                                div:first-child {
                                    display: unset;
                                }

                                @include desktop {
                                    grid-template-columns: auto 1fr max-content;
                                    justify-items: flex-end;
                                    column-gap: 60px;
                                    row-gap: 0;
                                }

                                .LogoWrapper {
                                    grid-row: 1/1;
                                    align-self: center;
                                    display: block;
                                    max-width: 252px;

                                    .Image {
                                        margin: 0 auto;
                                        &_ratio_square {
                                            padding-bottom: 0;
                                            height: auto;
                                        }

                                        &-Image {
                                            position: relative;
                                        }
                                    }
                                }

                                .SocialLinksWrapper {
                                    grid-column: 1/1;
                                    grid-row: 2/2;
                                    justify-self: flex-start;

                                    ul {
                                        display: grid;
                                        grid-template-columns: repeat(
                                            2,
                                            min-content
                                        );
                                        gap: 20px;
                                        justify-content: center;

                                        @include desktop {
                                            justify-content: flex-start;
                                        }

                                        li {
                                            padding-bottom: 0;

                                            a {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                width: 50px;
                                                height: 50px;
                                                background-color: $vialli-lightgray2;

                                                .Image {
                                                    height: 26px;
                                                    width: auto;
                                                    text-align: center;
                                                }
                                            }
                                        }
                                    }
                                }

                                @media (max-width: 450px) {
                                    .LogoWrapper,
                                    .SocialLinksWrapper {
                                        justify-self: center;
                                    }
                                }

                                div {
                                    @include desktop {
                                        grid-row: 1/3;

                                        ul {
                                            display: block;

                                            li {
                                                max-width: max-content;
                                            }
                                        }
                                    }

                                    h3 {
                                        margin-bottom: 7px;
                                        font-size: 20px;
                                        font-family: $vialli-secondary-font;
                                        font-weight: 400;
                                        text-align: center;
                                        @include desktop {
                                            margin-bottom: 14px;
                                            text-align: left;
                                            font-size: 25px;
                                        }
                                    }

                                    ul {
                                        li {
                                            margin: 0;
                                            padding: 0 0 4px;
                                            font-size: 14px;
                                            text-align: center;

                                            @include desktop {
                                                padding-bottom: 11px;
                                                font-size: 18px;
                                                text-align: left;
                                            }

                                            a {
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &_isNewsletter {
                        justify-content: flex-start;
                        padding: var(--column-content-padding-logo);
                        padding-top: 26px;
                        width: 100%;
                        min-height: unset;
                        background-color: $vialli-darkgray1;
                        color: $white;

                        @include desktop {
                            padding-top: 20px;
                            padding-bottom: 40px;
                        }
                    }
                }
            }

            &-ColumnTitle {
                margin-bottom: 12px;
                font-size: 25px;
                line-height: 33px;
                font-family: $vialli-secondary-font;
                font-weight: 400;
                text-transform: none;

                @include desktop {
                    margin-bottom: 8px;
                    font-size: 40px;
                    line-height: 53px;
                }
            }

            &-ColumnDescription {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                max-width: 388px;
                font-size: 14px;
                line-height: 20px;

                @include desktop {
                    max-width: 614px;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        .Footer-Copyright {
        }
    }
}
