:root {
    --heart-color: #DA4B7A;
}

body {
    &.mepal {
        .ProductWishlistButton {
            margin-left: 24px;

            &-Button {
                &:not([disabled]) {

                    &,
                    &:hover,
                    &:focus {
                        border-radius: 50%;
                        background-color: transparent;
                        border-color: var(--heart-color);
                    }
                }
            }

            &-Heart {
                --wishlist-heart-background: var(--heart-color);

            }
        }
    }
}
