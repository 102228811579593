@mixin multiline-ellipsis($lines: 3) {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin arrow($arrowSize: 15px, $left: 50%, $isRight: true) {
    $transform: translate(-50%, -50%);
    $transform-hover: $transform scale(1.2);

    &::before {
        content: "";
        position: absolute;
        left: $left;
        top: 50%;
        transform: $transform rotate(45deg);
        transition: transform 200ms;
        display: block;
        height: $arrowSize;
        width: $arrowSize;
        border-style: solid;
        border-width: 0 0 2px 2px;
    }

    &:hover {
        &::before {
            transform: $transform-hover rotate(45deg);
        }
    }

    @if $isRight {
        &::before {
            transform: $transform rotate(-135deg);
        }

        &:hover {
            &::before {
                transform: $transform-hover rotate(-135deg);
            }
        }
    }
}

@mixin vialliListWrapper(
    $paddingInline,
    $areaTitle: "title",
    $areaList: "list"
) {
    padding-left: $paddingInline;
    padding-right: $paddingInline;

    display: grid;
    grid-template-columns: 1fr 1fr minmax(auto, var(--content-wrapper-width)) 1fr 1fr;
    grid-template-areas:
        "- -- #{$areaTitle}  ---- ---"
        "- #{$areaList} #{$areaList} #{$areaList} ---";
}
