body {
    font-family: $vialli-base-font;

    &.viallidesign {
        h2 {
            font-family: $vialli-secondary-font;
            font-weight: 400;
            line-height: 40px;
            font-size: 3rem;

            @include xl-min {
                font-size: 6rem;
                line-height: 80px;
            }
        }
    }
}
