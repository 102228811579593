@mixin pseudoelement {
    content: '';
    position: absolute;
    top: 0;
    width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem);
    height: 100%;
    z-index: -1;
    background-color: $vialli-lightgray2;
}

body {
    &.viallidesign {
        --text-color-gray: #4B5563;

        @include mobile {
            main {
                margin-top: 0;
            }
        }

        .Header-Title_isVisible {
            line-height: unset !important;
            font-size: 1.8rem !important;
        }

        .Breadcrumbs {
            text-align: right;
            background-color: transparent;

            @include mobile {
                display: block;
            }

            .Breadcrumbs-List {
                height: auto;

                li:last-child {
                    color: var(--text-color-gray);
                }
            }

            .ContentWrapper {
                //&::after {
                //    @include pseudoelement;
                //    right: -100%;
                //}

            }
        }

        main.PostsListing {
            .ContentWrapper {
                @include desktop {
                    padding: 0;
                    padding-left: 1.2rem;
                }

                .CmsPage-Heading {
                    text-align: right;
                    background-color: $vialli-lightgray2;
                    position: relative;
                    font-family: $vialli-secondary-font;
                    padding-right: 30px;

                    @include mobile {
                        padding-right: 15px !important;
                    }


                    &::after {
                        @include pseudoelement;
                        right: -100%;
                    }


                    @include mobile {
                        &::before {
                            @include pseudoelement;
                            left: -100%;
                        }
                    }
                }

                .PostsListing-ColumnWrapper {
                    .PostsListing-Grid {
                        margin-top: 70px;
                        @include desktop {
                            grid-template-columns: repeat(4, 1fr);
                        }

                        .BlogPostCard {
                            padding-bottom: 250px;
                            margin-bottom: -250px;
                            overflow-x: hidden;
                            overflow-y: visible;

                            .BlogPostCard-ContentWrapper_expand {
                                background-color: $vialli-lightgray2;
                                padding: 0 20px 40px;

                                .BlogPostCard-ImageWrapper {
                                    position: relative;

                                    &::before {
                                        content: '';
                                        background-color: #fff;
                                        top: 0;
                                        left: -20%;
                                        right: 0;
                                        height: 50%;
                                        width: 150%;
                                        z-index: 2;
                                        position: absolute;
                                    }

                                    .BlogPostCard-Image {
                                        z-index: 3;
                                    }
                                }

                                .BlogPostCard-Details {
                                    position: relative;

                                    .BlogPostCard-Extra-Information {
                                        display: none;
                                    }

                                    .BlogPostCard-Title, .BlogPostCard-DescriptionWrapper p {
                                        color: $vialli-darkgray3;
                                        text-align: center;
                                        font-size: 16px;
                                        font-weight: 300;
                                    }

                                    .BlogPostCard-Title {
                                        font-family: $vialli-secondary-font;
                                        font-weight: bold;
                                        font-size: 25px;
                                    }

                                    .BlogPostCard-DescriptionWrapper p {
                                        font-family: $vialli-base-font;
                                    }

                                    .BlogRecentPosts-ReadMore {
                                        align-self: center;
                                        position: absolute;
                                        bottom: -66px;
                                        left: 50%;
                                        padding: 12px 0;
                                        text-align: center;
                                        text-transform: uppercase;
                                        font-weight: 400;
                                        font-size: 20px;
                                        width: 90%;
                                        border: 2px solid $vialli-darkgray3;
                                        background-color: $white;
                                        transform: translateX(-50%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
