body {
    &.viallidesign {
        --option-size: 30px;

        .ProductAttributeValue {
            &-Color,
            &-Image-Overlay{
                border-radius: 0;
            }

            &-Text{
                align-items: center;

                label{
                    &:first-of-type ~ label {
                        top: initial;
                    }
                }
            }
        }
    }
}
