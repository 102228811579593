body {
    &.viallidesign {
        .Breadcrumbs {
            display: block;
            text-align: right;
            margin-top: 140px;
            background: transparent;
            color: $vialli-darkgray1;
            line-height: 25px;
            z-index: 2;


            @include mobile {
                margin-top: 98px;
            }

            &-List {
                padding: 24px 20px;
                height: auto;
                @include desktop {
                    padding: 24px 0;
                }
            }


            .ContentWrapper {
                background-color: transparent;
                @include mobile {
                    padding: 0;
                }
            }

            .Breadcrumb {
                font-size: 14px;
                @include desktop {
                    font-size: 16px;
                }
                line-height: 1.75;
                padding: 0 10px 0 0;

                &:not(:last-child) {

                    &:after {
                        content: "/";
                        border: none;
                        height: auto;
                        width: auto;
                        transform: none;
                        top: 1px;
                        margin-left: 10px;
                    }
                }
            }


            nav {
                --breadcrumbs-color: #{$vialli-darkgray1};
                --breadcrumbs-active-color: #{$vialli-darkgray3};

                background-color: $vialli-lightgray2;

                @include desktop {

                    &:after {
                        display: block;
                        position: absolute;
                        width: calc(((100vw - var(--content-wrapper-width)) / 2) + 1.2rem);
                        background-color: $vialli-lightgray2;
                        content: '';
                        left: 100%;
                        top: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}
