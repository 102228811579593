body {
    --bottom-navigation-background: #000;

    &.mepal {
        --bottom-navigation-background: var(--mepal-primary-color);
    }

    &.mepal,
    &.mepalmio,
    &.kulig,
    &.brabantia,
    &.viallidesign {

        @include mobile {
            --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + var(--navigation-tabs-height));
        }

        .Header {
            @include mobile {
                top: calc(var(--demo-notice-height) + var(--navigation-tabs-height));

                --header-icon-stroke-width: 1px;
                --header-color: #000;
                background: #fff;

                &_name_default {
                    top: 0;
                }


                html.hideOnScroll & {
                    transform: translateY(-100%);
                    transition: transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715), -webkit-transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
                }
            }
        }


        .NavigationTabs {

            @include mobile {
                --header-color: #fff;
                top: 0;
                bottom: auto;
                background-color: var(--bottom-navigation-background);


                html.hideOnScroll & {
                    transform: translateY(-100%);
                }

                &-Wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: auto;
                }

                &-Nav {
                    justify-content: normal;
                }

                &-Button {
                    width: auto;
                    flex-grow: inherit;
                    display: block;


                    .Header-Button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 45px;
                        padding: 0 7.5px;

                        &:before,
                        &:after {
                            content: none;
                        }
                    }

                    &.Logo {
                        max-width: 90px;
                        height: 20px;
                        margin-left: 36px;

                        svg,
                        img {
                            position: relative;
                        }
                    }
                }
            }
        }

        .ProductActions-AddToCartWrapper {
            bottom: 0;
        }
    }
}
