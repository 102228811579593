/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --homepage-slider-color: #{$white};
}

.homepage-slider {
    --button-background: var(--homepage-button-background);
    --button-color: var(--homepage-button-color);
    --button-border: transparent;
    --button-hover-background: var(--homepage-button-color);
    --button-hover-color: var(--homepage-button-background);
    --button-hover-border: transparent;
    --button-padding: 13px 40px;

    h1 {
        font-size: 62px;
        text-transform: uppercase;
        color: var(--homepage-slider-color);
        margin-bottom: 2.5rem;

        @include mobile {
            font-size: 30px;
            margin-bottom: 1rem;
        }
    }

    h2 {
        @include after-mobile {
            font-size: 2.3rem;
        }
    }

    .Slider-Crumbs {
        bottom: 2rem;
    }

    .HomepageCategories-Button {
        &.Button {
            text-align: center;
            margin-bottom: 1rem;
            font-weight: 600;
            color: #fff;
            background-color: #000;
            font-size: 12px;
            padding: .5rem;

            @include after-mobile {
                padding: 1rem 2rem;
                font-size: 15px;
            }
        }
    }

    .SliderWidget {
        &-Figure {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-Figcaption {
            left: auto;
            text-align: center;
            max-width: 560px;

            &:not(:empty) {
                background: rgba(255, 255, 255, .86);
                padding: 1rem 2rem;
            }

            @include desktop {
                top: 65%;
            }

            @include after-mobile {
                padding: 3rem;
            }
        }
    }
}
