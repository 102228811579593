/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";
@import "../../style/abstract/mixins";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-second-level-color: #000;
    --font-weight-header: 500;
}


.Menu {

    &-MenuWrapper,
    &-SubCategoriesWrapper {
        .Image {
            $size: 40px;
            width: $size;
            height: $size;
        }
    }

    &-Image {
        &_type {
            &_main {
                grid-column: auto;
            }
        }
    }


    &-ItemFigure {
        grid-template-columns: auto 1fr;
        grid-gap: 8px;

        &_type {
            &_main {
                grid-column: auto;

                @include before-desktop {
                    grid-template-columns: 100%;
                }
            }

            &_subcategory {
                padding: 1rem 0;

                figcaption {
                    padding-left: 0;
                }
            }
        }
    }

    &-ItemCaption {
        font-weight: var(--font-weight-header);

        &_type_main {
            font-weight: var(--font-weight-header);
        }
    }

    &-Item {
        @include after-mobile {
            padding: 0 1rem;
        }
    }

    &-ItemList {
        &_type {
            &_subcategory {
                padding-bottom: 0;
            }
        }
    }

    &-SubItemWrapper {
        font-size: 14px;
        flex-basis: 305px;

        > .Menu-Link {

            @include desktop {
                font-size: 1.6rem;
            }
        }
    }

    &-Link {
        @include mobile {
            white-space: normal;
        }
    }

    &-SubCategoriesWrapperInner {
        transition: none;
        will-change: auto;
    }

    &-MainCategories {
        @include after-mobile {
            left: 240px;
        }
    }
}
