/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";
@import "../../style/abstract/mixins";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Field {

    label {
        line-height: 20px;
    }

    &_type {
        &_number {
            &,
            &.ProductActions-Qty {

                @include desktop {
                    display: inline-flex;
                    order: 0;
                }

                input {
                    text-align: center;
                    padding-left: 0;
                    order: initial;
                    border-left: 0;
                    border-right: 0;
                }

                button {
                    border: 1px solid var(--input-border-color);
                    width: var(--input-actions-button-width);
                    height: auto;
                    position: relative;
                    left: auto;
                }
            }
        }

        &_checkbox,
        &_radio {
            display: flex;

            label {
                padding-bottom: 0;
                order: 1;

                &:not(.Field-Label) {
                    order: 0;
                    margin-right: 1rem;
                }

                &:first-of-type {
                    ~ label {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    [type='checkbox'],
    [type='radio'] {
        + label {
            $size: 1.5rem;
            min-width: $size;
            min-height: $size;
        }
    }
}
