/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --homepage-jeans-background: #f8f8f8;
}

.homage-jeans-preview {
    padding: 2rem 4rem;
    background: var(--homepage-jeans-background);
    margin: 2rem 0 1rem;

    @include mobile {
        padding: 2rem 1rem;
    }

    h2 {
        margin: 0;
    }
}
