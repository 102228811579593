body {
    &.mepal {
        .HomepageCategories {
            display: grid;
            grid-gap: 42px 8px;
            grid-template-columns: 1fr;
            margin-bottom: 100px;

            @include mobile {
                margin-left: -16px;
                margin-right: -16px;
            }

            @include after-mobile {
                grid-gap: 96px 16px;
                grid-template-columns: repeat(2, 1fr);
            }

            @include desktop {
                grid-template-columns: repeat(3, 1fr);
            }

            &-Figure {

                @include after-mobile {
                    height: 270px;
                }
            }

            &-Button.Button {
                padding: 0;

                &,
                &:focus,
                &:hover {
                    background-color: transparent;
                    opacity: 1;
                }

                strong {
                    bottom: auto;
                    opacity: 1;
                    color: #fff;
                    top: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--mepal-secondary-color);
                    width: calc(100% - 48px);
                    margin: auto;
                    margin-top: -16px;
                    border-radius: 0 0 12px 12px;
                    font-size: 16px;
                    padding: 8px 24px;

                    @include mobile {
                        max-width: 280px;
                    }

                    @include after-mobile {
                        margin-top: -32px;
                        font-size: 24px;
                        line-height: 32px;
                        padding: 16px 8px;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1500px) {
                        min-height: 96px;
                    }
                }
            }
        }
    }
}
