/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";
@import "../../style/abstract/mixins";

.CheckoutSuccess {
    @include mobile {
        margin-top: 2.8rem;
        text-align: center;
    }

    &-ContinueButton {
        @include mobile {
            width: 100%;
        }

        @include after-mobile {
            margin-top: 2.4rem;
        }
    }

    &-ButtonWrapper {
        @include mobile {
            padding: 1.4rem;
            position: fixed;
            left: 0;
            width: 100%;
            bottom: var(--navigation-tabs-height);
            border-top: 1px solid var(--primary-divider-color);
        }
    }

    .Button {
        @include before-desktop {
            width: 100%;
            text-align: center;
        }
    }
}
