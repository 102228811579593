body {
    &.mepal {
        .ProductSeries {
            background-color: var(--mepal-primary-color);
            padding: 0 16px;
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding-bottom: 14px;

            @include desktop {
                padding-bottom: 72px;
            }

            &-Wrapper {
                max-width: var(--content-wrapper-width);
                margin: 0 auto;

                .Widget-Title {
                    @include mobile {
                        padding-top: 32px;
                    }
                }
            }

            &-Content {
                display: flex;
                flex-wrap: wrap;

                section {
                    display: none;
                    order: 2;
                    width: 100%;
                    flex-basis: auto;
                    grid-template-columns: 1fr;
                    margin: 24px 0;

                    @include desktop {
                        grid-template-columns: repeat(2, 1fr);
                        flex-direction: row;
                        grid-auto-rows: 1fr;
                    }

                    .Box {
                        flex: 1;
                        padding: 24px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .Image {
                            height: 100%;
                            padding-bottom: 67.5%;


                            @include desktop {
                                padding-bottom: 0;
                                margin: 0;
                            }
                        }

                        @include desktop {
                            min-height: 400px;
                            padding: 40px;
                        }

                        &:nth-child(2) {
                            padding: 0;

                            @include desktop {
                                margin-left: -32px;
                                margin-top: 32px;
                                margin-bottom: -32px;
                            }
                        }

                        h3 {
                            margin-top: 0;
                            margin-bottom: 16px;
                            line-height: 32px;
                            font-weight: 500;
                            font-size: 24px;
                            color: var(--mepal-secondary-color);

                            @include desktop {
                                line-height: 48px;
                                font-size: 40px;
                            }
                        }

                        p {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 24px;

                            @include desktop {
                                font-size: 16px;
                                line-height: 32px;
                            }
                        }

                        .Button {
                            margin-top: 36px;
                            @extend .Button-Strong;

                            font-size: 20px;
                        }

                        iframe {
                            width: 100%;
                            flex-grow: 1;
                            height: 240px;
                            //max-height: 450px;
                        }

                        &-White {
                            background-color: #fff;
                        }

                        &-Primary {
                            background-color: var(--mepal-primary-color);
                        }

                        &-Secondary {
                            background-color: var(--mepal-secondary-color);

                            h3,
                            p {
                                color: #fff;
                            }
                        }
                    }


                    &.Clear {
                        margin-top: 64px;

                        @include desktop {
                            margin-top: 128px;
                        }

                        .Box {
                            padding: 0;
                            margin: 0;

                            &-Text {
                                @include desktop {
                                    padding: 64px;
                                }
                            }
                        }
                    }
                }

                input {
                    position: absolute;
                    pointer-events: none;
                    border: none;
                    padding: 0;
                    left: -900%;
                    background-color: transparent;
                }

                label {
                    font-weight: 300;
                    color: #fff;
                    cursor: pointer;
                    order: 1;
                    margin: 12px;
                    font-size: 16px;
                    line-height: 24px;

                    @include desktop {
                        font-size: 21px;
                        margin: 12px 24px;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }
                }

                input:checked + label {
                    font-weight: 700;
                    text-decoration: underline;
                }


                input:checked + label + section {
                    display: grid;
                }
            }

            &-NoBackground {
                background-color: transparent;
            }

            &-Background {
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                padding: 190px 16px 16px 16px;

                @include desktop {
                    padding: 40px 16px;
                }

                section {
                    margin-top: 0;

                    .Box {
                        h3 {
                            color: var(--mepal-primary-color);
                        }

                        .Button {
                            @extend .Button-Strong;
                            margin-left: auto;
                            margin-right: auto;

                            &:not([disabled]) {
                                &,
                                &:hover,
                                &:focus {
                                    border-color: var(--mepal-primary-color);
                                }
                            }
                        }
                    }
                }
            }

            &.AlwaysShow {
                .ProductSeries-Content {
                    section {
                        display: grid;
                    }
                }
            }
        }
    }
}
