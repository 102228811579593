:root {
    --mepal-label-new-color: var(--mepal-primary-color);
    --mepal-label-promotion-color: #da4b7a;
    --mepal-label-sale-color: #F5A061;
}

body {
    &.mepal {
        .special_status_product_list_item {
            div {
                text-transform: uppercase;
                color: #fff;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                padding: 4px 8px;

                @include desktop {
                    padding: 0 12px;
                    font-size: 16px;
                    line-height: 32px;
                }

                &.new {
                    background-color: var(--mepal-label-new-color);
                }

                &.promotion {
                    background-color: var(--mepal-label-promotion-color);
                }

                &.sale {
                    background-color: var(--mepal-label-sale-color);
                }
            }
        }
    }
}
