/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";
@import "../../style/abstract/mixins";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-logo-width: 156px;
    --header-logo-height: 44px;
    --font-weight-header: 500;
    --header-dots-color: white;

    @include desktop {
        --header-nav-height: 60px;
        --header-height: calc(var(--header-nav-height));
    }
}

.Header {
    &-LogoWrapper {
        position: static;
        margin-left: 0;

        .Image-Image {
            object-fit: contain;
            width: auto;
        }
    }

    &-Button {
        &_type {
            &_share {
                display: none;
            }
        }
    }

    &-MinicartButtonWrapper {
        border-right: none;
    }

    &-MinicartTitle {
        font-weight: var(--font-weight-header);
    }

    &-MyAccountTitle {
        font-weight: var(--font-weight-header);
    }

    html.hiddenNavigationTabs & {
        top: 0 !important;
    }
}

