@media only screen and (min-width: 320px) and (max-width: 767px) {
    body {
        -webkit-text-size-adjust: none;
    }
}

.ContentWrapper.ProductTabs-Wrapper {
    padding-left: 0;
    padding-right: 0;
}

.Content {
    &-Layout-2,
    &-Layout-1 {
        .Image {
            height: auto;

            &_ratio_square {
                padding-bottom: 0;
            }

            img {
                position: relative;
                height: auto !important;
            }
        }
    }

    &-Layout-2 {
        grid-template-columns: 1fr 560px;
        grid-gap: 100px;

        @include desktop {
            display: grid;
        }

        p,
        ul li,
        ol li {
            font-size: 14px;

            @include desktop {
                font-size: 16px;
            }
        }

        ul,
        ol {
            margin: 32px 0;

            li {
                line-height: 1.3;
                margin-bottom: 16px;
                padding-left: 24px;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    position: relative;
                    width: 24px;
                    height: 1px;
                    background-color: #000;
                    display: inline-block;
                    vertical-align: super;
                    left: auto;
                    padding-right: 0;
                    margin-right: 10px;
                }
            }
        }

        p {
            line-height: 32px;
            margin-bottom: 32px;

            &.bigger {
                font-size: 20px;
            }
        }
    }

    &-Layout-1 {
        margin-left: -45px;
        margin-right: -45px;

        &.images {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .Image {
                margin: 20px 45px;
                max-width: 95px;

                @include after-mobile() {
                    max-width: 200px;
                }
            }
        }
    }
}
