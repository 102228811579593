body {
    &.viallidesign {
        .Menu {
            --menu-item-hover-color: $vialli-darkgray3;

            @include desktop {
                &-MainCategories {
                    position: relative;
                    left: auto;
                    padding-right: 0;
                }

                &-MenuWrapper {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 3;
                    grid-row-end: 3;
                    margin: 0;
                }

                &-ItemList {
                    margin-left: auto;
                }

                &-SubCategories {
                    padding: 65px 20px 45px 20px;
                }

                &-SubMenu {
                    display: flex;
                    align-items: flex-end;
                    margin-left: auto;
                    text-align: right;
                }

                &-SubItemWrapper {
                    font-size: 14px;
                    flex-basis: 25%;
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-right: 0;
                }

                &-Link {
                    &:hover,
                    &:focus {
                        color: $vialli-darkgray3;
                    }

                    &_isHovered {
                        .Menu-ItemCaption_type_main {
                            border-bottom: 2px solid $vialli-darkgray3;
                        }
                    }
                }

                &-ItemCaption_type_main {
                    font-size: 16px;
                    border-bottom: 2px solid transparent;
                }

                &-ItemList_type_subcategory {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                    row-gap: 36px;
                    column-gap: 24px;

                    .Menu {
                        &-ItemCaption {
                            border-bottom: 2px solid transparent;

                            &:hover {
                                border-bottom: 2px solid $vialli-darkgray3;
                            }
                        }

                        &-ItemCaption {
                            grid-gap: 0;
                        }
                    }
                }
            }
        }
    }
}
