body {
    &.viallidesign {
        .NavigationTabs {
            --header-nav-height: 84px;
            --header-height: 84px;
            background-color: $vialli-lightgray1;
            border-top: 16px solid $vialli-darkgray2;
            display: block;

            &-Nav {
                .Logo {
                    height: 52px;
                }
            }

            &-Wrapper {
                position: static;

                .NavigationTabs-Button:not(.Logo)[aria-label*="Home"] {
                    position: absolute;
                    right: 20px;
                    top: 3px;
                }
            }

            &-Button {
                &[aria-label*="search"],
                &[aria-label*="Home"] {
                    svg {
                        circle,
                        path {
                            stroke: $vialli-darkgray3;
                        }
                    }
                }

                &[aria-label*="search"] {
                    @include mobile {
                        display: none;
                    }
                }

                &[aria-label*="Minicart"] {
                    .Header-Button {
                        &:before {
                            background-image: url('/style/icons/sale.svg');
                            height: 20px;
                            width: 20px;
                            border: none;
                            border-radius: 0;
                            display: block;
                            background-size: 20px;
                            position: relative;
                            margin: 0;
                            content: '';
                            top: 0;
                        }

                        svg {
                            display: none;
                        }
                    }
                }

                &[aria-label*="account"] {
                    margin-right: 45px;

                    .Header-Button {
                        &:before {
                            background-image: url('/style/icons/user.svg');
                            height: 20px;
                            width: 20px;
                            border: none;
                            border-radius: 0;
                            display: block;
                            background-size: 20px;
                            position: relative;
                            margin: 0;
                            content: '';
                            top: 0;
                        }

                        svg {
                            display: none;
                        }
                    }
                }

                &.Logo {
                    margin-left: 20px;
                    max-width: none;
                }
            }
        }
    }
}
