body {
    &.mepal {
        .ExpandableContent {
            &-Heading {
                color: var(--mepal-primary-color);
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
            }


            .ProductAttributeValue-Image-Overlay {
                img {
                    margin-bottom: 0;
                }
            }
        }
    }
}
