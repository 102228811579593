/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";
@import "../../style/abstract/mixins";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: 480px;
    --copyright-background-color: #f3f3f3;
    --column-content-padding: 4rem 6rem;
    --column-copyright-padding: 1rem 6rem;
    --column-content-padding-logo: 4rem 2rem;
    --column-button-collor: #000;

    @include mobile {
        --column-content-padding: 2rem 2rem;
        --column-copyright-padding: 1rem;
    }

    --footer-background-color: #fff;
}

.Footer {
    overflow: hidden;
    margin-bottom: 0;
    background: var(--footer-background-color);
    border-top: solid 1px #f3f3f3;

    .ColumnContent {
        width: 100%;
    }

    &-Columns {
        flex-direction: column-reverse;
        max-width: none;
        padding: 0;
    }

    &-Wrapper {
        width: 100%;
        margin: auto 0;

        @include desktop {
            display: flex;
        }
    }

    &-Column {
        a {
            color: var(--column-button-collor);
        }

        .Logo,
        .Logo-Placeholder {
            margin-top: 0;
        }

        .Logo-Placeholder {
            position: relative;
        }
    }

    .Logo-Column {
        width: 20%;
    }

    .Content-Column {
        width: 100%;
        grid-column: 3 / -1;
    }

    &-Content {
        background-color: var(--footer-background-color);

        .CmsBlock {
            &-Wrapper {
                width: 100%;

                .NewsletterWrapper {
                    width: 100%;
                    background: #f0f0f0;
                    padding: 32px 0;
                    min-height: 250px;

                    h3 {
                        margin-top: 0;
                    }

                    p {
                        margin-bottom: 32px;
                    }

                    button {
                        max-width: 100px;
                        width: 100%;

                        @media (max-width: 450px) {
                            max-width: none;
                        }

                        &[disabled] {
                            opacity: 1;
                        }
                    }

                    input {
                        width: 100%;
                        max-width: 300px;

                        @media (max-width: 450px) {
                            max-width: none;
                        }
                    }

                    .newsletter-sign-in-email span {
                        width: 100%;
                        max-width: 400px;
                        display: block;
                        margin: 8px auto;
                        text-align: left;

                        @media (max-width: 450px) {
                            text-align: center;
                        }
                    }
                }

                .ContentWrapper {
                    color: #000;
                    display: grid;
                    grid-gap: 5rem;
                    grid-template-columns: repeat(4, 1fr);
                    padding: var(--column-content-padding-logo);

                    div:first-child {
                        display: none;

                        @include desktop {
                            display: block;
                        }
                    }

                    @include before-desktop {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media (max-width: 450px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    > div {
                        text-align: left;

                        .Image-Image {
                            object-position: 0;
                            height: auto;
                        }

                        h3 {
                            margin-top: 0;
                            //white-space: nowrap;

                            @include mobile {
                                white-space: normal;
                            }
                        }


                        details {
                            summary {
                                position: relative;
                                margin-bottom: 1.8rem;
                                cursor: pointer;
                                width: 90%;

                                &::-webkit-details-marker {
                                    display: none;
                                }


                                @include desktop() {
                                    pointer-events: none;
                                }

                                @include mobile {
                                    &:after {
                                        $size: 8px;
                                        content: "";
                                        position: absolute;
                                        z-index: 0;
                                        width: $size;
                                        height: $size;
                                        right: 0;
                                        border-color: #fff;

                                        top: 50%;
                                        transform: translate(-50%, -50%) rotate(
                                                135deg);
                                        transition: transform 200ms;
                                        display: block;
                                        border-style: solid;
                                        border-width: 0 0 2px 2px;
                                    }
                                }
                            }


                            &[open] {
                                summary {
                                    @include mobile {
                                        &:after {
                                            transform: translate(-50%, -50%) rotate(
                                                    -45deg);
                                        }
                                    }
                                }
                            }
                        }

                        .BlogRecentPosts-Title {
                            display: none;
                        }

                        ul {
                            margin: 0;
                            padding: 0;
                            list-style-type: none;

                            &.BlogRecentPosts-List {
                                display: flex;
                                grid-gap: 0;

                                li {
                                    .BlogRecentPosts {
                                        &-ImageWrapper,
                                        &-Extra-Information,
                                        &-ShortContent,
                                        &-ReadMore {
                                            display: none;
                                        }

                                        &-PostTitle {
                                            margin: 0;
                                        }
                                    }

                                    h3.BlogRecentPosts-PostTitle {
                                        font-size: 16px !important;
                                        font-weight: 400;
                                        text-transform: none;
                                        margin-bottom: 0;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        height: 25px;

                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }

                            li {
                                line-height: 28px;
                                width: 100%;
                                padding: 8px 0 8px 0;

                                &::before {
                                    content: none;
                                }

                                div {
                                    font-weight: 600;
                                    display: flex;

                                    a {
                                        font-weight: 400;
                                        //white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-Copyright {
        display: block;
        align-items: center;
        margin-top: 0;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: black;
        grid-template-columns: none;
        padding: var(--column-copyright-padding);
        background-color: var(--copyright-background-color);

        @include mobile {
            text-align: center;
        }

        &::after {
            display: none;
        }

        &-Wrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            max-width: var(--content-wrapper-width);
            margin: 0 auto;

            @include mobile {
                flex-flow: column nowrap;
                justify-content: flex-start;
                align-items: flex-start;

                margin-bottom: 2rem;
            }

            > div {
                align-items: center;
                display: flex;

                strong {
                    margin: 0 4px;
                }

                @include mobile {
                    width: 100%;
                    margin: 1rem 0;
                    display: block;
                }
            }
        }

        &-Author {
            display: flex !important;
            flex-flow: row nowrap;

            @include mobile {
                justify-content: center;
            }

            @include after-mobile {
                justify-content: flex-end;
            }

            > span {
                white-space: nowrap;
            }

            a {
                width: 100px;
                display: block;
            }

            img {
                display: block;
            }
        }
    }

    &-CmsBlockWrapper {

        .Footer-Column_isNewsletter {
            text-align: center;
            min-height: 250px;

            display: flex;
            justify-content: center;
            flex-direction: column;


            .Field {
                input {
                    border: 1px solid $black;
                }
            }

            @include desktop {
                padding-left: 0;
            }
        }
    }
}
