body {
    &.mepal {

        .ContactFormPage {
            text-align: center;
            display: grid;
            grid-template-columns: 1fr;
            margin-bottom: 64px;
            margin-top: 32px;

            @include mobile {
                grid-gap: 32px;
            }

            @include after-mobile {
                grid-template-columns: repeat(3, 1fr);
            }

            div {


                .Image_ratio_square {
                    margin-bottom: 8px;
                    display:flex;
                    justify-content: center;

                    @include after-mobile {
                        padding: 0;
                    }

                    .Image-Image {
                        @include mobile {
                            position: relative;
                            margin: auto;
                        }
                    }
                }

                p {
                    max-width: none;
                    margin: 0;

                   & ,
                   a {
                       color: var(--mepal-third-color);
                       font-size: 16px;
                       font-style: normal;
                       font-weight: 500;
                       line-height: 32px;
                    }
                }
            }
        }
    }
}
