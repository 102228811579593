/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


body {
    &.mepal {

        .BlogRelatedPosts {
            &-Title {
                padding-bottom: 0;

                @include desktop() {
                    font-size: 48px;
                }
            }
        }

        .HomePage {

            .BlogRecentPosts,
            .BlogPostCard {

                &-Title {
                    color: var(--mepal-primary-color);
                }
            }
        }

        .BlogRecentPosts,
        .BlogPostCard {

            &-Title,
            &-PostTitle {
                color: var(--mepal-secondary-color);
            }

            &-DescriptionWrapper p,
            &-ShortContent p,
            &-ReadMore {
                color: var(--mepal-third-color);
            }

            &-ReadMore {
                text-transform: lowercase;
            }
        }
    }
}

