:root {
    --item-margin: 24px;
}

.Downloads {
    &-List {
        display: flex;
        flex-wrap: wrap;
        margin-left: calc(var(--item-margin) * -1);
        margin-right: calc(var(--item-margin) * -1);

        &-Item {
            width: 100%;
            text-align: left;
            padding-left: 0;

            &,
            &:last-child {
                margin: 48px 24px;
            }

            &-Wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
            }

            @include after-mobile {
                width: calc(25% - (var(--item-margin)) * 2);
            }

            @include desktop {
                width: calc(20% - (var(--item-margin)) * 2);
            }

            &,
            a {
                &:hover,
                &:focus,
                &:link,
                &:visited {
                    text-decoration: none;
                }
            }

            &::before {
                content: none;
            }

            &-Image {
                display: block;
                width: 100%;
            }

            &-Title {
                color: #000;
                margin: 36px 0;
                font-size: 18px;
                font-weight: 500;
            }

            &-Button {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
                font-size: 14px;

                &::before {
                    $size: 14px;
                    //content: '';
                    display: inline-block;
                    width: $size;
                    height: $size;
                    background-size: cover;
                    background-repeat: no-repeat;
                    //margin-right: 8px;
                    //background-image: url('/media/ageno_image/download.png');
                }
            }
        }
    }
}
