body {
    &.viallidesign {
        --logo-height: 37px;

        .Slider {
            &-Arrow {
                z-index: 1;
            }
        }
    }
}
