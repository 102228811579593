body {
    &.mepal {
        .ReadMore {
            text-align: center;
            margin: 100px auto;
            max-width: 705px;

            h3 {
                color: var(--mepal-primary-color);
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;

                @include desktop {
                    font-size: 40px;
                    line-height: 48px;
                    margin-bottom: 40px;
                }
            }

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: var(--mepal-third-color);

                @include desktop {
                    font-size: 16px;
                    line-height: 32px;
                }
            }

            .Button {
                margin-top: 26px;
                @extend .Button-Strong;
                min-width: 170px;
            }
        }
    }
}
