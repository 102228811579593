body {
    &.mepal {
        .ProductLinks {
            &-Title {
                @extend .Widget-Title;
            }
        }

        .react-multi-carousel {
            &-dot-list.Slider-Crumbs {
                li {

                    &.react-multi-carousel-dot--active {
                        button {
                            background-color: var(--mepal-secondary-color);
                        }
                    }

                    button {
                        border: 1px solid var(--mepal-secondary-color);
                    }
                }
            }
        }
    }
}

