body {
    &.viallidesign {
        table{
            thead{
                tr{
                    border-bottom: none;
                }
            }
        }
        .MyAccount {
            background-color: $white;

            &-Wrapper {
                padding-top: 0;

                .MyAccountAddressBook-Button,
                .MyAccountMyWishlist-ActionBar {
                    position: relative;

                    button {
                        display: block;
                    }
                }

                .MyAccountNewsletterSubscription-Button {
                    display: block;
                }
            }

            &-Heading {
                margin: 0 -14px 24px -14px;
                font-size: 25px;
                line-height: 1.32;
                text-transform: none;
                font-family: $vialli-secondary-font;
                font-weight: normal;
                text-align: right;
                display: block;
                background-color: $vialli-lightgray2;

                padding: 24px 20px;

                @include desktop {
                    font-size: 60px;
                    margin: 0 0 32px 0;
                    padding: 20px 24px 24px;

                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 1;

                    &:after {
                        display: block;
                        position: absolute;
                        width: 100%;
                        background-color: $vialli-lightgray2;
                        content: '';
                        left: 100%;
                        top: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}
