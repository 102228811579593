body {
    &.mepal {
        .ProductActions {
            .Field_type_number {

                button {
                    height: 100%;
                    max-height: 46px;

                    &:first-child {
                        border-radius: 0 0 0 12px;
                        order: 1;
                    }

                    &:last-child {
                        border-radius: 0 0 12px 0;
                        order: 3;
                    }

                    > span {
                        vertical-align: middle;
                    }
                }
            }

            .Field_type_number button,
            .ProductActions-Qty {
                border-color: #BABCCC;
            }

            .ProductActions-Qty {
                order: 2;
            }
        }
    }
}
