body {
    &.mepal {
        .ProductConfigurableAttributes {
            &-Expandable {
                --product-configurable-attributes-margin: 40px 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
