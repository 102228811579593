body {
    &.mepal {

        .SearchField {
            &-Input {
                @include before-desktop {
                    --search-field-background: #E6F3F3;
                }
            }

            &-Placeholder {
                &:before {
                    --header-color: #000;
                }
            }
        }
    }
}
