/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../../../src/style/abstract/variables";
@import "../../../../../src/style/abstract/media";
@import "../../../../../src/style/abstract/button";
@import "../../../../../src/style/abstract/loader";
@import "../../../../../src/style/abstract/icons";
@import "../../../../../src/style/abstract/parts";
@import "../../../../../src/style/abstract/mixins";

.Paczkomaty {

    &-Modal {

        opacity: 0;
        pointer-events: none;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.40);
        width: 100%;
        height: 100%;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;

        &_Open {
            opacity: 1;
            pointer-events: all;
        }

        .CloseModal {
            $size: 32px;
            position: absolute;
            top: -22px;
            right: -14px;
            cursor: pointer;
            background-color: #000;
            width: $size;
            height: $size;

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 20px;
                transform-origin: 50% 50%;
                top: 7px;
                right: 15px;
                transform: rotate(-45deg);
                background-color: #fff;
            }

            &::after {
                transform: rotate(45deg);
            }
        }

        #easypack-map {
            max-width: 1000px;
            max-height: 600px;
            margin: 0 32px;
            background: white;

            a + a {
                margin-left: 0;
            }

            li {
                padding-left: 0;

                &:before {
                    content: none;
                }
            }
        }
    }

    &-PointSelector {
        margin-top: 1rem;
        padding-bottom: 2rem;
    }

    &-SelectedPaczkomatInforamtion {
        margin-top: 2rem;
        font-weight: bolder;
    }
}

.leaflet {
    &-popup {
        p {
            line-height: 1.1;
        }

        a + a {
            margin-left: 0;
        }
    }
}
