body {
    &.mepal {
        .Breadcrumbs {
            --breadcrumbs-background: #e6f3f3;

            .Breadcrumb {
                font-size: 14px;
            }
        }
    }
}
