body {
    //&:not(.mepal) {
    .CartOverlay,
    .CartPage {

        &-Promo,
        &-PromoBlock {
            display: none;
        }

    }

    //}

    .CartOverlay,
    .CartPage {

        &-Promo,
        &-PromoBlock {
            background: var(--cart-page-totals-background);
            border: none;
        }
    }
}

